import { useNavigate, useSearchParams } from 'react-router-dom';

import styles from '../product.module.scss';
import useScrollToTop from '../../common/hook/useScrollToTop';

const PaymentCompletePage = () => {
  const [params] = useSearchParams();
  const navigate = useNavigate();

  useScrollToTop();

  const onContinueClick = () => {
    const url = params.get('continueUrl');
    if (!url) return;

    navigate(url);
  };

  return (
    <div className={styles.PaymentPage}>
      <div>
        <h1>Thank you so much and enjoy Clear!</h1>
        <p>Check your email for your license key and payment information.</p>
        <button
          onClick={onContinueClick}
          style={{ textTransform: 'uppercase' }}
        >
          Download
        </button>
      </div>
    </div>
  );
};

export default PaymentCompletePage;
