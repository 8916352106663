import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import Footer from './Footer';
import Header from './Header';
import CookieConsentPopup from '../../legal/CookieConsentPopup';

import styles from './layout.module.scss';

import '../assets/styles/main-v4.scss';
import Banner from './Banner';

const Layout = () => {
  // Add layout-v4 class to body.
  useEffect(() => {
    document.body.classList.add('layout-v4');

    return () => {
      document.body.classList.remove('layout-v4');
    };
  }, []);

  return (
    <div className={styles.Layout}>
      <Banner>
        <div>Join Our Shift Open Beta 2: Available from June 27, 2024</div>
      </Banner>
      <Header />
      <div className={styles.ContentContainer}>
        <Outlet />
      </div>
      <Footer />

      <CookieConsentPopup />
    </div>
  );
};

export default Layout;
