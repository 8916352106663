import { ReactComponent as IconLogo } from '../../common/assets/images/v4/product/clear/clear-icon-logo.svg';
import { ReactComponent as C } from '../../common/assets/images/v4/product/clear/big-letters/C.svg';
import { ReactComponent as L } from '../../common/assets/images/v4/product/clear/big-letters/L.svg';
import { ReactComponent as E } from '../../common/assets/images/v4/product/clear/big-letters/E.svg';
import { ReactComponent as A } from '../../common/assets/images/v4/product/clear/big-letters/A.svg';
import { ReactComponent as R } from '../../common/assets/images/v4/product/clear/big-letters/R.svg';
import { ReactComponent as V_S } from '../../common/assets/images/v4/product/clear/small-letters/V.svg';
import { ReactComponent as O_S } from '../../common/assets/images/v4/product/clear/small-letters/O.svg';
import { ReactComponent as I_S } from '../../common/assets/images/v4/product/clear/small-letters/I.svg';
import { ReactComponent as C_S } from '../../common/assets/images/v4/product/clear/small-letters/C.svg';
import { ReactComponent as E_S } from '../../common/assets/images/v4/product/clear/small-letters/E.svg';
import { ReactComponent as S_S } from '../../common/assets/images/v4/product/clear/small-letters/S.svg';
import { ReactComponent as P_S } from '../../common/assets/images/v4/product/clear/small-letters/P.svg';
import { ReactComponent as A_S } from '../../common/assets/images/v4/product/clear/small-letters/A.svg';
import { ReactComponent as R_S } from '../../common/assets/images/v4/product/clear/small-letters/R.svg';
import { ReactComponent as T_S } from '../../common/assets/images/v4/product/clear/small-letters/T.svg';

import styles from './clear.module.scss';

const ClearLogo = () => (
  <>
    <div>
      <IconLogo />
    </div>
    <div className={styles.Big}>
      <C />
      <L />
      <E />
      <A />
      <R />
    </div>
    <div className={styles.Small}>
      <V_S />
      <O_S style={{ marginLeft: '-.5px' }} />
      <I_S />
      <C_S />
      <E_S />
      <S_S style={{ marginLeft: '14px' }} />
      <E_S />
      <P_S />
      <A_S style={{ marginLeft: '-.5px' }} />
      <R_S />
      <A_S />
      <T_S style={{ marginLeft: '-.5px' }} />
      <O_S style={{ marginLeft: '-.5px' }} />
      <R_S />
    </div>
  </>
);

export default ClearLogo;
