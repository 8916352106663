import { useNavigate, useSearchParams } from 'react-router-dom';

import styles from '../product.module.scss';
import useScrollToTop from '../../common/hook/useScrollToTop';

const PaymentIncompletePage = () => {
  const [params] = useSearchParams();
  const navigate = useNavigate();

  useScrollToTop();

  const onGoBackClick = () => {
    const url = params.get('continueUrl');
    if (!url) return;

    navigate(url);
  };

  return (
    <div className={styles.PaymentPage}>
      <div>
        <h1 className={styles.Warning}>
          We had trouble processing your payment.
        </h1>
        <p>Please try again or visit our help center for support.</p>
        <button onClick={onGoBackClick}>Go Back</button>
      </div>
    </div>
  );
};

export default PaymentIncompletePage;
