import { ReactComponent as IconLogo } from '../../common/assets/images/v4/product/air/LOGO_AIR.svg';

const AirLogo = () => (
  <>
    <div>
      <IconLogo />
    </div>
  </>
);

export default AirLogo;
