import useScrollToTop from '../common/hook/useScrollToTop';
import { ReactComponent as LogoImage } from '../common/assets/images/v4/product/shift/Shift_Logo.svg';
import { ReactComponent as OpenBetaImage } from '../common/assets/images/v4/product/shift/OpenBeta.svg';
import { ReactComponent as S } from '../common/assets/images/v4/product/shift/big-letters/S.svg';
import { ReactComponent as H } from '../common/assets/images/v4/product/shift/big-letters/H.svg';
import { ReactComponent as I } from '../common/assets/images/v4/product/shift/big-letters/I.svg';
import { ReactComponent as F } from '../common/assets/images/v4/product/shift/big-letters/F.svg';
import { ReactComponent as T } from '../common/assets/images/v4/product/shift/big-letters/T.svg';
import { ReactComponent as LinkArrowIcon } from '../common/assets/images/v4/clear_arrow_wht.svg';
import { ReactComponent as IconLogo } from '../common/assets/images/v4/product/clear/clear-icon-logo.svg';
import { ReactComponent as C } from '../common/assets/images/v4/product/clear/big-letters/C.svg';
import { ReactComponent as L } from '../common/assets/images/v4/product/clear/big-letters/L.svg';
import { ReactComponent as E } from '../common/assets/images/v4/product/clear/big-letters/E.svg';
import { ReactComponent as A } from '../common/assets/images/v4/product/clear/big-letters/A.svg';
import { ReactComponent as R } from '../common/assets/images/v4/product/clear/big-letters/R.svg';
import { ReactComponent as ShiftDescription } from '../common/assets/images/v4/product/shift/Shift_description.svg';
import styles from './product.module.scss';

const ProductPage = () => {
  useScrollToTop();

  const onShiftClick = () => {
    window.location.href = '/shift';
  };

  const onClearClick = () => {
    window.location.href = '/clear';
  };

  return (
    <div className={styles.ProductPage}>
      <div className={styles.ProductShift}>
        <div className={styles.ProductContents}>
          <div className={styles.ProductTitle}>
            <LogoImage className={styles.ShiftLogo} />
            <h2>
              <S />
              <H />
              <I />
              <F />
              <T />
            </h2>
            <div className={styles.ProductDescription}>
              <ShiftDescription />
            </div>
            <OpenBetaImage className={styles.OpenBeta} />
          </div>
          <div className={styles.ProductSubtitle}>
            <p>
              Introducing Supertone Shift
              <br />
              Real-Time Voice Changer
            </p>
          </div>
          <div className={styles.ProductActions}>
            <button onClick={onShiftClick}>
              Learn Shift <LinkArrowIcon />
            </button>
          </div>
        </div>
        <div className={styles.ProductUi} />
        <div className={styles.ProductBg} />
      </div>

      <div className={styles.ProductClear}>
        <div className={styles.ProductContents}>
          <div className={styles.ProductTitle}>
            <IconLogo style={{ width: '90px', height: '46px' }} />
            <h2>
              <C />
              <L />
              <E />
              <A />
              <R />
            </h2>

            <div className={styles.ProductDescription}>
              De-Noise & De-Reverb
              <br />
              Voice Separator
            </div>
          </div>
          <div className={styles.ProductSubtitle}>
            <p>
              Super Clear,
              <br />
              Simply Powerful
            </p>
          </div>
          <div className={styles.ProductActions}>
            <button onClick={onClearClick}>
              Learn Clear <LinkArrowIcon />
            </button>
          </div>
        </div>
        <div className={styles.ProductUi} />
        <div className={styles.ProductBg} />
      </div>
    </div>
  );
};

export default ProductPage;
