import { ReactComponent as SupertoneSymbol } from '../../common/assets/logos/v4/supertone-symbol.svg';
import { ReactComponent as TwitterIcon } from '../../common/assets/logos/v4/Social Icons _ Twitter.svg';
import { ReactComponent as DiscordIcon } from '../../common/assets/logos/v4/Social Icons _ Discord.svg';

import Newsletter from './Newsletter';
import { Link } from 'react-router-dom';
import cn from 'classnames';

import styles from './layout.module.scss';

const Footer = () => {
  const onTwitterClick = () => {
    window.location.href = 'https://twitter.com/Supertone_ai';
  };

  const onDiscordClick = () => {
    window.location.href = 'https://discord.gg/M3h5GJzQbG';
  };

  return (
    <footer className={styles.Footer}>
      <div>
        <div className={styles.FooterLine} />

        <div className={styles.FooterGrid}>
          <section className={cn(styles.FooterLogo, 'footer-grid-item')}>
            <SupertoneSymbol />
          </section>
          <section className={cn(styles.FooterCompany, 'footer-grid-item')}>
            <div>
              <ul className={styles.FooterMenuItems}>
                <li>
                  <Link to="https://supertone.ai">About Supertone</Link>
                </li>
                <li>
                  <a href="/privacy-policy">Privacy Policy</a>
                </li>
                <li>
                  <a href="/refund-policy">Refund Policy</a>
                </li>
                <li>
                  <a href="/cookie-policy">Cookie Policy</a>
                </li>
                <li>
                  <a href="/cookie-settings">Cookie Settings</a>
                </li>
              </ul>
              <div className={cn(styles.FooterCopyright, 'desktop-only')}>
                © {new Date().getFullYear()} Supertone Inc. All rights reserved
              </div>
            </div>
          </section>
          <section className={cn(styles.FooterNewsletter, 'footer-grid-item')}>
            <Newsletter
              componentId="8920959"
              formId="103427772410496825"
              personalInformationLink="/personal-information-newsletter"
            />
          </section>
          <section className={cn(styles.FooterSNS, 'footer-grid-item')}>
            <DiscordIcon onClick={onDiscordClick} />
            <TwitterIcon onClick={onTwitterClick} />
          </section>
          <div className={cn(styles.FooterCopyright, 'mobile-only')}>
            © {new Date().getFullYear()} Supertone Inc. All rights reserved
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
