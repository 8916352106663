import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import cn from 'classnames';

import { ReactComponent as SupertoneLogo } from '../../common/assets/logos/v4/supertone-logo.svg';
import { ReactComponent as HamburgerIcon } from '../../common/assets/images/v4/hamburger.svg';
import { ReactComponent as CloseIcon } from '../../common/assets/images/v4/close.svg';

import styles from './layout.module.scss';

const Header = () => {
  const location = useLocation();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  // Disable body scroll when mobile menu is open.
  useEffect(() => {
    if (isMobileMenuOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [isMobileMenuOpen]);

  // Close mobile menu when route changes.
  useEffect(() => {
    setIsMobileMenuOpen(false);
  }, [location.pathname]);

  const onHamburgerClick = () => {
    setIsMobileMenuOpen(true);
  };

  const onMobileMenuClose = () => {
    setIsMobileMenuOpen(false);
  };

  const onMenuItemClick = () => {
    setIsMobileMenuOpen(false);
  };

  return (
    <>
      {!isMobileMenuOpen ? (
        <div className={styles.Header}>
          <span className={styles.Logo}>
            <a href="/">
              <SupertoneLogo />
            </a>
          </span>

          <ul className={cn(styles.MenuItems, 'desktop-tablet-only')}>
            <li>
              <a
                href="/shift"
                className={
                  location.pathname === '/shift' ? styles.SelectedLink : ''
                }
              >
                Shift
              </a>
            </li>
            <li>
              <a
                href="/clear"
                className={
                  location.pathname === '/clear' ? styles.SelectedLink : ''
                }
              >
                Clear
              </a>
            </li>
            <li>
              <a href="https://supertone.ai/products-air">Air</a>
            </li>
          </ul>
          <ul className={cn(styles.SecondaryMenuItems, 'desktop-tablet-only')}>
            <li>
              <Link to="https://support.supertone.ai">Support</Link>
            </li>
          </ul>

          <span className="mobile-only">
            <button onClick={onHamburgerClick}>
              <HamburgerIcon />
            </button>
          </span>
        </div>
      ) : (
        <div className={styles.MobileMenu}>
          <div className={styles.Header}>
            <span className={styles.Logo}>
              <a href="/">
                <SupertoneLogo />
              </a>
            </span>

            <span className="mobile-only">
              <button onClick={onMobileMenuClose}>
                <CloseIcon />
              </button>
            </span>
          </div>

          <div className={styles.Body}>
            <ul className={cn(styles.MenuItems, 'mobile-only')}>
              <li>
                <a
                  href="/shift"
                  style={{ letterSpacing: '2px', textTransform: 'uppercase' }}
                  className={
                    location.pathname === '/shift' ? styles.SelectedLink : ''
                  }
                  onClick={onMenuItemClick}
                >
                  Shift
                </a>
              </li>
              <li>
                <a
                  href="/clear"
                  style={{ letterSpacing: '2px', textTransform: 'uppercase' }}
                  className={
                    location.pathname === '/clear' ? styles.SelectedLink : ''
                  }
                  onClick={onMenuItemClick}
                >
                  Clear
                </a>
              </li>
              <li>
                <a
                  href="https://supertone.ai/products-air"
                  style={{ letterSpacing: '2px', textTransform: 'uppercase' }}
                  onClick={onMenuItemClick}
                >
                  Air
                </a>
              </li>
            </ul>

            <ul className={cn(styles.MenuItems, 'mobile-only')}>
              <li>
                <Link to="https://support.supertone.ai">Support</Link>
              </li>
            </ul>

            <ul className={styles.FooterMenuItems}>
              <li>
                <Link to="https://supertone.ai">About Supertone</Link>
              </li>
              <li>
                <a href="/privacy-policy">Privacy Policy</a>
              </li>
              <li>
                <a href="/refund-policy">Refund Policy</a>
              </li>
              <li>
                <a href="/cookie-policy">Cookie Policy</a>
              </li>
              <li>
                <a href="/cookie-settings">Cookie Settings</a>
              </li>
            </ul>
          </div>
        </div>
      )}
    </>
  );
};

export default Header;
