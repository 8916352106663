import React from 'react';

const ShiftIcon: React.FC = () => {
  return (
    <svg
      width="40"
      height="24"
      viewBox="0 0 63 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_4362_17587)">
        <path d="M61.9805 13.21L61.9905 13.22L61.9805 13.21Z" fill="#CDCDCD" />
        <path
          d="M54.3895 10.4797C51.7795 10.4797 49.4395 11.6597 47.8895 13.5197C47.8695 13.5497 34.8495 30.3097 31.4395 33.2497C34.7695 36.1597 39.1295 37.9297 43.9095 37.9297C54.3895 37.9297 62.8795 29.4397 62.8795 18.9597C62.8795 18.6397 62.8595 18.3197 62.8195 17.9997C62.3395 13.7597 58.7495 10.4697 54.3895 10.4697V10.4797Z"
          fill="#CDCDCD"
        />
        <path
          d="M62.8694 18.9702V18.8102L62.8594 18.4102C62.8594 18.5902 62.8694 18.7702 62.8694 18.9602V18.9702Z"
          fill="#CDCDCD"
        />
        <path
          d="M8.49 27.45C11.1 27.45 13.39 26.4 15 24.41C16.61 22.42 27.17 8.24 31.45 4.68C28.1 1.77 23.74 0 18.97 0C8.49 0 0 8.49 0 18.97C0.36 23.75 3.8 27.46 8.49 27.46V27.45Z"
          fill="#FFC300"
        />
        <path
          d="M62.86 18.41C62.86 18.3 62.84 18.19 62.84 18.08V17.99V17.97C62.75 16.33 62.47 14.73 61.99 13.23C61.99 13.23 61.99 13.23 61.99 13.22C59.55 5.55 52.38 0 43.91 0C39.13 0 34.84 1.85 31.44 4.68C27.16 8.24 16.6 22.42 14.99 24.41C13.38 26.4 11.1 27.45 8.48 27.45C3.8 27.45 0.36 23.74 0 18.97C0 29.45 8.49 37.94 18.97 37.94C23.75 37.94 28.11 36.17 31.44 33.26C34.84 30.31 47.86 13.56 47.89 13.53C49.45 11.67 51.78 10.49 54.39 10.49C58.75 10.49 62.34 13.78 62.82 18.02C62.86 18.33 62.88 18.65 62.88 18.98C62.88 18.79 62.88 18.61 62.87 18.42L62.86 18.41Z"
          fill="#1772FF"
        />
      </g>
      <defs>
        <clipPath id="clip0_4362_17587">
          <rect width="62.87" height="37.93" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ShiftIcon;
