import { useEffect, useState } from 'react';
import cn from 'classnames';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { Blue } from '../common/v4/colors';
import { getCookie, setCookie } from '../common/cookie';
import { ReactComponent as LinkArrowIcon } from '../common/assets/images/v4/clear_arrow_wht.svg';

import styles from './legal.module.scss';

interface CookieConsentPopupProps {
  className?: string;
}

const PRODUCT_HOST = process.env.REACT_APP_PRODUCT_HOST as string;

const CookieConsentPopup = ({ className = '' }: CookieConsentPopupProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const hasConsent = JSON.parse(getCookie('sup_consent') as string);

    setIsOpen(!hasConsent);
  }, [location.pathname]);

  const onAcceptClick = () => {
    gtag('consent', 'update', {
      analytics_storage: 'granted',
    });

    setCookie('sup_consent', JSON.stringify({ analytics: true }), 365);
    setIsOpen(false);
  };

  const onConfigureClick = () => {
    // Cookie settings page is only available in the product site.
    if (window.location.hostname === PRODUCT_HOST) {
      navigate('/cookie-settings');
    } else {
      window.location.href = `https://${PRODUCT_HOST}/cookie-settings`;
    }
  };

  const onRejectClick = () => {
    setCookie('sup_consent', JSON.stringify({ analytics: false }), 365);
    setIsOpen(false);
  };

  return (
    <div
      className={cn(styles.CookieConsentPopup, className)}
      style={{
        display: isOpen ? 'block' : 'none',
      }}
    >
      <p>
        Please allow Supertone to collect cookies for a smooth experience!{' '}
        <Link to="https://product.supertone.ai/cookie-policy">More</Link>
      </p>
      <div>
        <button
          className={styles.NoOutline}
          onClick={onRejectClick}
          style={{ maxWidth: '120px' }}
        >
          Reject
        </button>
        <button onClick={onConfigureClick}>
          Configure <LinkArrowIcon />
        </button>
        <button
          className={cn(styles.Primary, 'btn-primary')}
          onClick={onAcceptClick}
        >
          Accept All <LinkArrowIcon fill={Blue} />
        </button>
      </div>
    </div>
  );
};

export default CookieConsentPopup;
