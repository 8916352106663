import { useEffect } from 'react';

// Scroll to top when the page is loaded.
const useScrollToTop = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
};

export default useScrollToTop;
