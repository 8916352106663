import { useEffect, useId, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import useScrollToTop from '../common/hook/useScrollToTop';
import { getCookie, setCookie } from '../common/cookie';
import { ReactComponent as LinkArrowIcon } from '../common/assets/images/v4/clear_arrow_wht.svg';

import styles from './legal.module.scss';

const CookieSettingsPage = () => {
  const navigate = useNavigate();
  const [isOptionalChecked, setIsOptionalChecked] = useState(false);
  const acceptAllId = useId();
  const acceptEssentialId = useId();
  const acceptOptionalId = useId();

  useScrollToTop();

  useEffect(() => {
    const hasConsent = JSON.parse(getCookie('sup_consent') as string);
    if (!hasConsent) return;

    if (hasConsent.analytics) {
      setIsOptionalChecked(true);
    }
  }, []);

  const onAcceptAllChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const tobe = event.target.checked;

    setIsOptionalChecked(tobe);
  };

  const onAcceptOptionalChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setIsOptionalChecked(event.target.checked);
  };

  const onSaveClick = () => {
    if (isOptionalChecked) {
      gtag('consent', 'update', {
        analytics_storage: 'granted',
      });

      setCookie('sup_consent', JSON.stringify({ analytics: true }), 365);
    } else {
      setCookie('sup_consent', JSON.stringify({ analytics: false }), 365);
    }

    navigate('/');
  };

  const onDisagreeAllClick = () => {
    setCookie('sup_consent', JSON.stringify({ analytics: false }), 365);

    navigate('/');
  };

  const isAllAccepted = isOptionalChecked;
  const isSaveEnabled = true;

  return (
    <div className={styles.LegalContainer}>
      <h1>Cookie Settings</h1>

      <h2>Consent to Collection of Cookies</h2>

      <p style={{ marginBottom: '65px' }}>
        Please allow Supertone to collect cookies for a better experience!
      </p>

      <section>
        <div className={styles.CheckboxContainer}>
          <input
            type="checkbox"
            id={acceptAllId}
            className={styles.Checkbox}
            checked={isAllAccepted}
            onChange={onAcceptAllChange}
          />
          <label htmlFor={acceptAllId}>Accept all</label>
        </div>
        <small>
          Supertone, Inc., and its partners, use cookies and similar
          technologies (collectively "technologies") to store and access
          information on your device. Some of these technologies are technically
          essential to provide you with a secure, well-functioning and reliable
          website. We would also like to use optional/non-essential technologies
          to give you the best user experience. The optional technologies make
          it possible to analyse the audience of our website, to display
          personalized advertising on third-party sites based on your profile,
          to track your location, to carry out targeted marketing campaigns and
          to personalize the content of our website depending on your usage.
          Through these technologies, we will collect information such as the
          interactions between you and our website, your preferences and your
          browsing habits.If you are happy for technologies to be used for these
          purposes, click on "Accept All" to accept all of the technologies. You
          are also able to manage which cookies can be used on your device
          below. You can change your preferences at any time by clicking on the
          "Cookie Preferences" icon at the bottom of our website. Your choice
          will be retained for 12 months. Further information is also available
          in our <a href="/cookie-policy">Cookie Policy</a> and our{' '}
          <a href="/privacy-policy">Privacy Policy</a>.
        </small>
      </section>

      <div className={styles.Line} />

      <section>
        <div className={styles.CheckboxContainer}>
          <input
            type="checkbox"
            id={acceptEssentialId}
            className={styles.Checkbox}
            checked={true}
            disabled={true}
          />
          <label htmlFor={acceptEssentialId}>
            Essential Cookies{' '}
            <span className={styles.Required}>/ Required</span>
          </label>
        </div>
        <small>
          These cookies are strictly necessary for the provision of the service
          that you have expressly requested or have the sole purpose of enabling
          or facilitating communication by electronic means. For example, they
          allow us to remember the items you have placed in your shopping
          basket. These cookies are automatically activated and cannot be
          deactivated because they are essential to enable you to browse our
          site. <a href="/cookie-policy">VIEW COOKIES</a>
        </small>
      </section>

      <section>
        <div className={styles.CheckboxContainer}>
          <input
            type="checkbox"
            id={acceptOptionalId}
            className={styles.Checkbox}
            checked={isOptionalChecked}
            onChange={onAcceptOptionalChange}
          />
          <label htmlFor={acceptOptionalId}>
            Analytical/Performance Cookies
          </label>
        </div>
        <small>
          These cookies allow us to understand how you access the site and your
          browsing habits (e.g. pages viewed, time spent on a page, content
          clicked). They allow us to analyze the performance and design of our
          site and to detect possible errors. Owing to these performance
          indicators, we are constantly improving our site and our products,
          content and offers. <a href="/cookie-policy">VIEW COOKIES</a>
        </small>
      </section>

      <div
        style={{
          marginTop: '95px',
          marginBottom: '151px',
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <button onClick={onDisagreeAllClick}>
          Disagree All <LinkArrowIcon />
        </button>
        <button
          onClick={onSaveClick}
          className={styles.Primary}
          disabled={!isSaveEnabled}
        >
          Save <LinkArrowIcon />
        </button>
      </div>
    </div>
  );
};

export default CookieSettingsPage;
