/* eslint-disable react/jsx-pascal-case */
/* This is to allow V1_1 in the component name */

import { BrowserRouter, Route, Routes } from 'react-router-dom';

import LayoutV4 from '../common/v4/Layout';
import DownloadClearPage from './clear/DownloadClearPage';
import PaymentCompletePage from '../product/payment/PaymentCompletePage';
import PaymentIncompletePage from '../product/payment/PaymentIncompletePage';
import PrivacyPolicyPage from '../legal/PrivacyPolicyPageV1_2';
import PrivacyPolicyPageV1_1 from '../legal/PrivacyPolicyPageV1_1';
import RefundPolicyPage from '../legal/RefundPolicyPage';
import TermsOfUsePage from '../legal/TermsOfUsePage';
import CookieSettingsPage from '../legal/CookieSettingsPage';
import CookiePolicyPage from '../legal/CookiePolicyPage';
import PersonalInformationNewsletterPage from '../legal/PersonalInformationNewsletterPage';
import PersonalInformationDownloadPage from '../legal/PersonalInformationDownloadPage';
import ActivateClearPage from './clear/ActivateClearPage';
import ProductPage from './ProductPage';
import OpenShiftAppPage from './shift/OpenShiftAppPage';
import ActivateAirPage from './air/ActivateAirPage';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<LayoutV4 />}>
          <Route path="/download/clear" element={<DownloadClearPage />} />
          <Route path="/activate/clear" element={<ActivateClearPage />} />

          <Route path="/activate/air" element={<ActivateAirPage />} />

          <Route path="/open/shift" element={<OpenShiftAppPage />} />

          <Route path="/payment-complete" element={<PaymentCompletePage />} />
          <Route
            path="/payment-incomplete"
            element={<PaymentIncompletePage />}
          />

          <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
          <Route
            path="/privacy-policy-1.1"
            element={<PrivacyPolicyPageV1_1 />}
          />

          <Route path="/refund-policy" element={<RefundPolicyPage />} />
          <Route path="/terms-of-use" element={<TermsOfUsePage />} />
          <Route path="/cookie-settings" element={<CookieSettingsPage />} />
          <Route path="/cookie-policy" element={<CookiePolicyPage />} />
          <Route
            path="/personal-information-newsletter"
            element={<PersonalInformationNewsletterPage />}
          />
          <Route
            path="/personal-information-download"
            element={<PersonalInformationDownloadPage />}
          />

          <Route index element={<ProductPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
