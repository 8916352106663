import useScrollToTop from '../common/hook/useScrollToTop';

import styles from './legal.module.scss';

const TermsOfUsePage = () => {
  useScrollToTop();

  return (
    <div className={styles.LegalContainer}>
      <h1>Supertone License Agreement</h1>

      <p>
        Please read this end user licence agreement carefully, as it sets out
        the basis upon which we license the Software for use.
      </p>

      <h2>Definitions</h2>

      <p>
        "EULA" means this end user licence agreement "SOFTWARE" means the
        software which accompanies this license "SUPERTONE" means Supertone,
        Inc., Seolleungro 667 3F, Seoul, Korea, Republic of "USER" means the
        person to whom SUPERTONE grants a right to use the SOFTWARE under this
        EULA
      </p>

      <h2>Agreement</h2>

      <p>
        The SOFTWARE is the property of SUPERTONE and is protected by copyright
        law. This EULA is a legally binding contract between the USER and
        SUPERTONE. This EULA describes the User's rights and the conditions upon
        which the SOFTWARE may be used. By accepting this agreement or by
        installing and using the SOFTWARE, the USER agrees to all of these
        terms. If the USER does not agree to these terms, the SOFTWARE or its
        features may not be used.
      </p>

      <h2>Applicability</h2>

      <p>
        This license agreement applies to the SOFTWARE which the USER may use as
        Trial, and/or thereafter subject to an acquired license of the SOFTWARE
        of any version, including any and all upgrades, supplements or services,
        unless other terms come with such versions, upgrades or supplements.
      </p>

      <h2>Support</h2>

      <p>
        SUPERTONE shall give its reasonable commercial efforts to service any
        major version of the SOFTWARE including email support. SUPERTONE is
        entitled to terminate support for any major version of any SUPERTONE
        software at any time at its sole discretion.
      </p>

      <h2>Third Party Software</h2>

      <p>
        AAX is a trademarks of Avid Technology, inc.
        <br />
        Audio Units is a trademark of Apple Computer, inc.
        <br />
        VST PlugIn Technology by Steinberg Media Technologies and VST
        <br />
        PlugIn Interface Technology by Steinberg Media Technologies GmbH.
      </p>

      <p>
        Steinberg License:
        <br />
        LIMITATION OF LIABILITY
        <br />
        1.) Subject to the provisions in the following sub-sections, Steinberg
        shall only be liable, irrespective of the legal grounds, for damages
        caused by the intentional or grossly negligent conduct of Steinberg, its
        legal representatives, managerial employees or any other vicarious
        agents. In the case of damage caused by the grossly negligent conduct of
        any other vicarious agents, the liability shall be limited to those
        damages which must typically be expected within the scope of an
        agreement such as the present one. Any further liability other than as
        permitted under this agreement shall be excluded.
        <br />
        2.) Any liability of Steinberg for damages arising from violation of
        life, body and health, from the assumption of a guarantee or from a
        procurement risk as well as Steinberg's liability for damages pursuant
        to the Product Liability Act (Produkthaftungsgesetz) shall remain
        unaffected.
        <br />
        3.) To the extent the liability of Steinberg is excluded pursuant to the
        subsections of these provisions, this shall also apply to the benefit of
        Steinberg's employees in the event the Licensee files any claims
        directly against them.
        <br />
        PRODUCT WARRANTY
        <br />
        1.) Steinberg licences the VST PlugIn Interface Technology on an "AS IS"
        basis. Steinberg makes no warranties, express or implied, including
        without limitation the implied warranties of merchantability and fitness
        for a particular purpose, regarding the VST PlugIn Interface Technology
        or operation and use in combination with the Licenseeís program. Neither
        the Licensee, its employees, agents or Distributors have any right to
        make any other representation, warranty or promise with respect to the
        VST PlugIn Interface Technology.
        <br />
        2.) In no event shall Steinberg be liable for incidental, indirect or
        consequential damages arising from the use, or distribution of the VST
        PlugIn Interface Technology by the Licensee, whether theory of contract,
        product liability or otherwise. All claims for indemnification for
        losses by the Licensee itself or by third parties shall be excluded.
        <br />
        3.) Steinberg may in its sole discretion discontinue the distribution of
        the current VST PlugIn SDK and/or release improved versions of the
        Licensed Software Developer Kit but offers no commitment whatsoever
        those releases will occur at any time or for anybody.
        <br />
        INFRINGEMENT
        <br />
        1.) Steinberg represents and warrants that, as of the date of this
        Agreement, it is not aware of any claim or action alleging that VST, the
        VST PlugIn Interface Technology, or the content of the Licensed Software
        Developer Kit infringes any third party intellectual property right.
        <br />
        2.) Steinberg, however, disclaims any obligation of defense or indemnify
        of the Licensee or its customer with respect to any such claim or
        action, or otherwise arising out of this agreement. Steinberg shall have
        no liability arising out of any such actual or alleged intellectual
        property infringement. The Licensee, however, shall promptly notify
        Steinberg, in writing, of each such infringement claim of which the
        Licensee becomes aware. Steinberg may defend the Licensee against such
        claims. In such case, the Licensee is obligated to duly support
        Steinberg’s defense.
      </p>
    </div>
  );
};

export default TermsOfUsePage;
