import axios from 'axios';

export const apiGet = async (url: string, params?: any) => {
  return axios.get(url, { params });
};

export const apiPost = async (url: string, data?: any) => {
  return axios({
    method: 'post',
    url,
    data: JSON.stringify({ data }),
    headers: { 'Content-Type': 'application/json' },
  });
};
