import useScrollToTop from '../common/hook/useScrollToTop';

import styles from './legal.module.scss';

const PersonalInformationNewsletterPage = () => {
  useScrollToTop();

  return (
    <div className={styles.LegalContainer}>
      <h1>Consent for the Collection and Use of Personal Information</h1>

      <p>Category: Marketing Information Delivery</p>

      <p>Entity of Collection and Use: Supertone, Inc.</p>

      <p>
        Purpose of Collection and Use: To deliver marketing information related
        to the service
      </p>

      <p>Items of Collection and Use: Email address</p>

      <p>
        Retention and Use Period: The retention period is in accordance with
        relevant laws or until consent is withdrawn.
      </p>

      <p>
        - You have the right to refuse this consent, and if you refuse, the use
        of the service for the stated purpose may be limited.
      </p>
    </div>
  );
};

export default PersonalInformationNewsletterPage;
