export const VioletBlue = '#3636e4';

export const Blue = '#0061ff';

export const Green = '#31b743';

export const Red = '#ff5a00';

export const LightGray = '#afafaf';
export const LesserLightGray = '#a9a9a9';
export const LessLightGray = '#a5a5a5';
export const LighterGray = '#909090';
export const SlightlyLighterGray = '#767676';
export const Gray = '#696969';
export const DarkerGray = '#5b5b5b';
export const DarkGray = '#292929';
export const MoreDarkGray = '#161616';
