import { useLocation } from 'react-router-dom';
import { CSSProperties, useMemo, useState } from 'react';
import useSleep from '../../common/hook/useSleep';
import useInterval from '../../common/hook/useInterval';
import ShiftIcon from './ShiftIcon';

export function ModalContainer({ children }: { children: React.ReactNode }) {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '10rem',
        marginBottom: '10rem',
        color: 'black',
        textAlign: 'center',
      }}
    >
      <div
        style={{
          minWidth: '292px',
          backgroundColor: '#F4F4F4',
          borderRadius: '16px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '36px 24px 24px 24px',
          gap: '24px',
        }}
      >
        <ShiftIcon />
        {children}
      </div>
    </div>
  );
}

const OpenShiftAppPage = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const priceIdsStr = searchParams.get('priceIds');

  const priceIds = useMemo(() => priceIdsStr?.split(',') || [], [priceIdsStr]);

  const [isLoading, setIsLoading] = useState(true);
  const [progressCounter, setProgressCounter] = useState(0);
  const progressText = ['.', '..', '...'][progressCounter % 3];

  const shiftDeepLink = `shift://purchase?priceIds=${priceIds.join(',')}`;

  useInterval(() => {
    setProgressCounter(progressCounter + 1);
  }, 500);

  useSleep(() => {
    setIsLoading(false);
    window.location.href = shiftDeepLink;
  }, 5000);
  const modalTitleStyle: CSSProperties = {
    fontWeight: '500',
    fontSize: '17px',
    color: '#121212',
  };
  const ModalDescriptionStyle: CSSProperties = {
    fontWeight: '400',
    fontSize: '14px',
    color: '#2B2B2B',
  };
  return isLoading ? (
    <ModalContainer>
      <h1 style={modalTitleStyle}>
        Preparing your <br /> Supertone Shift
      </h1>
      <p style={ModalDescriptionStyle}>in progress {progressText}</p>
    </ModalContainer>
  ) : (
    <ModalContainer>
      <h1 style={modalTitleStyle}>Supertone Shift is ready!</h1>
      <p style={ModalDescriptionStyle}>
        Open Supertone Shift app and
        <br />
        enjoy your new voices.
      </p>
      <a
        href={shiftDeepLink}
        target="_blank"
        rel="noreferrer"
        style={{
          backgroundColor: '#0a5bff',
          color: '#f4f4f4',
          padding: '8px 16px', // px-4 py-2
          borderRadius: '0.375rem', // rounded-md
          fontSize: '16px', // text-lg
          fontWeight: '400',
          minWidth: '100%', // min-w-full
          display: 'inline-block',
          textAlign: 'center',
        }}
      >
        Open Supertone Shift
      </a>
    </ModalContainer>
  );
};

export default OpenShiftAppPage;
