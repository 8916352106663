import { ReactNode, useEffect, useId, useState } from 'react';

import { ReactComponent as LinkArrowIcon } from '../../common/assets/images/v4/clear_arrow_wht.svg';

import '../assets/styles/newsletter.scss';
import { Blue } from './colors';

interface NewsletterProps {
  componentId: string;
  formId: string;
  buttonLabel?: ReactNode;
  hideMarketingConsent?: boolean;
  successContent?: ReactNode;
  personalInformationLink: string;
}

const Newsletter = ({
  componentId,
  formId,
  buttonLabel = (
    <>
      Subscribe
      <LinkArrowIcon />
    </>
  ),
  hideMarketingConsent = false,
  successContent = (
    <>
      <h4>Thank you!</h4>
      <p>You have successfully joined our subscriber list.</p>
    </>
  ),
  personalInformationLink,
}: NewsletterProps) => {
  const [isAccepted1, setIsAccepted1] = useState(false);
  const [isAccepted2, setIsAccepted2] = useState(false);
  const [isAccepted3, setIsAccepted3] = useState(false);
  const allAcceptedId = useId();
  const accepted1Id = useId();
  const accepted2Id = useId();
  const accepted3Id = useId();
  const [email, setEmail] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);

  useEffect(() => {
    const callbackName = `ml_webform_success_${componentId}`;
    const oldFunc = window[callbackName];

    window[callbackName] = () => {
      setIsSuccess(true);
    };

    return () => {
      window[callbackName] = oldFunc;
    };
  }, [componentId, formId]);

  useEffect(() => {
    fetch(`https://assets.mailerlite.com/jsonp/137163/forms/${formId}/takel`);
  }, [formId]);

  const onEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const isAllAccepted = hideMarketingConsent
    ? isAccepted1 && isAccepted3
    : isAccepted1 && isAccepted2 && isAccepted3;
  const isSubmitEnabled = isAllAccepted && email;

  const onAcceptAllChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const tobe = !isAllAccepted;

    setIsAccepted1(tobe);
    setIsAccepted2(tobe);
    setIsAccepted3(tobe);
  };

  const onAccept1Change = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsAccepted1(e.target.checked);
  };

  const onAccept2Change = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsAccepted2(e.target.checked);
  };

  const onAccept3Change = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsAccepted3(e.target.checked);
  };

  return (
    <>
      <div
        id={`mlb2-${componentId}`}
        className={`ml-form-embedContainer ml-subscribe-form ml-subscribe-form-${componentId}`}
      >
        <div className="ml-form-align-center ">
          <div className="ml-form-embedWrapper embedForm">
            <div
              className="ml-form-embedBody ml-form-embedBodyDefault row-form"
              style={{ display: isSuccess ? 'none' : 'block' }}
            >
              <div className="ml-form-embedContent">
                <h4>Subscribe to our newsletter</h4>
                <p>
                  to get the latest updates on products, activities, and offers.
                  unsubscribe any time.
                </p>
              </div>

              <form
                className="ml-block-form"
                action={`https://assets.mailerlite.com/jsonp/137163/forms/${formId}/subscribe`}
                data-code=""
                method="post"
                target="_blank"
              >
                <div className="ml-form-formContent">
                  <div className="ml-form-fieldRow">
                    <div className="ml-field-group ml-validate-required">
                      {/*  checkboxes  */}
                      <div className="div-checkbox">
                        <input
                          id={allAcceptedId}
                          type="checkbox"
                          required={true}
                          checked={isAllAccepted}
                          onChange={onAcceptAllChange}
                        />
                        <label htmlFor={allAcceptedId}>
                          <strong>
                            Accept All{' '}
                            <span style={{ color: Blue }}>/ Required</span>
                          </strong>
                        </label>
                      </div>
                      <div
                        style={{
                          borderTop: '1px solid #444',
                          marginTop: '10px',
                          marginBottom: '10px',
                        }}
                      ></div>
                      <div className="div-checkbox">
                        <input
                          id={accepted1Id}
                          type="checkbox"
                          required={true}
                          checked={isAccepted1}
                          onChange={onAccept1Change}
                        />
                        <label htmlFor={accepted1Id}>
                          Accept Personal Information Use & Collection (
                          <a
                            href={personalInformationLink}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            More
                          </a>
                          )
                        </label>
                      </div>
                      {!hideMarketingConsent && (
                        <div className="div-checkbox">
                          <input
                            id={accepted2Id}
                            type="checkbox"
                            required={true}
                            checked={isAccepted2}
                            onChange={onAccept2Change}
                          />
                          <label htmlFor={accepted2Id}>
                            <span>Accept Marketing Emails</span>
                          </label>
                        </div>
                      )}
                      <div className="div-checkbox">
                        <input
                          id={accepted3Id}
                          type="checkbox"
                          required={true}
                          checked={isAccepted3}
                          onChange={onAccept3Change}
                        />
                        <label htmlFor={accepted3Id}>
                          <span>I am over 16 years old</span>
                        </label>
                      </div>
                      {/*  /checkboxes  */}
                      {/*  input  */}

                      {/*  /input  */}
                      {/*  textarea  */}
                      {/*  /textarea  */}
                      {/*  select  */}
                      {/*  /select  */}
                      {/*  radio  */}
                      {/*  /radio  */}
                      {/*  countries  */}
                      {/*  /countries  */}
                    </div>
                  </div>

                  <div className="ml-form-fieldRow ml-last-item">
                    <div className="ml-field-group ml-field-email ml-validate-email ml-validate-required">
                      <input
                        aria-label="email"
                        aria-required="true"
                        type="email"
                        className="form-control"
                        data-inputmask=""
                        name="fields[email]"
                        placeholder="Email Address"
                        autoComplete="email"
                        value={email}
                        onChange={onEmailChange}
                      />
                      <div className="ml-form-embedSubmit">
                        <button
                          type="submit"
                          className="primary"
                          disabled={!isSubmitEnabled}
                        >
                          {buttonLabel}
                        </button>

                        <button
                          disabled
                          style={{ display: 'none' }}
                          type="button"
                          className="loading"
                        >
                          <div className="ml-form-embedSubmitLoad"></div>
                          <span className="sr-only">Loading...</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                {/*  Privacy policy  */}

                {/*  /Privacy policy  */}

                <input type="hidden" name="ml-submit" value="1" />

                <input type="hidden" name="anticsrf" value="true" />
              </form>
            </div>

            <div
              className="ml-form-successBody row-success"
              style={{ display: isSuccess ? 'block' : 'none' }}
            >
              <div className="ml-form-successContent">{successContent}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Newsletter;
