import useScrollToTop from '../common/hook/useScrollToTop';

import styles from './legal.module.scss';

const PrivacyPolicyPage = () => {
  useScrollToTop();

  return (
    <div className={styles.LegalContainer}>
      <h1>Privacy Policy</h1>
      <h2>Article 1 Privacy Notice</h2>
      <p>
        This Privacy Policy (this “Policy”) is issued by Supertone, Inc.
        (“Supertone,” “Company,” “we,” “us,” or “our”) and is addressed to our
        general customers, visitors to our Sites, users of our Apps and other
        users of our services (together, “you”). In this Policy, the term “App”
        means any application made available by us (including where we make such
        applications available via third-party stores or marketplaces or by any
        other means), and the term “Site” means any website operated or
        maintained by us or on our behalf. However, services with their own
        privacy notice will adhere to their own privacy notice.
      </p>
      <p>
        As this Policy may be amended or updated from time to time, we encourage
        you to regularly check this Policy to review any changes to the terms.
      </p>
      <p>
        The definition of “personal information” used in this Policy is as
        follows:
      </p>
      <p>
        The term “personal information,” as used in this Policy, shall mean
        personal information as defined under Article 2 of the Personal
        Information Protection Act; for the purposes of the General Data
        Protection Regulation 2016/679 (the “GDPR”) and the UK Data Protection
        Act 2018, “personal information” shall mean information that is about
        any individual, or from which any individual is directly or indirectly
        identifiable, in particular by reference to an identifier such as a
        name, an identification number, location data, an online identifier or
        to one or more factors specific to the physical, physiological, genetic,
        mental, economic, cultural or social identity of that individual; and,
        for the purposes of the California Consumer Privacy Act (the “CCPA”),
        “personal information” shall be read to also include information that
        identifies, relates to, describes, is reasonably capable of being
        associated with, or could reasonably be linked, directly or indirectly,
        with a particular consumer or household.
      </p>
      <h2>Article 2 Purposes of Processing Personal Information</h2>
      <p>
        We process your personal information for the following purposes pursuant
        to applicable law:
      </p>
      <h3>Purposes of Processing</h3>
      <p>
        <strong>To provide our Sites, Apps, products and services:</strong>{' '}
        Providing our Sites, Apps, products or services; creating an account;
        providing customer support on the use of services; identifying and
        preventing wrongful uses; providing functions for joining the and for
        activities; performing statistical analyses; providing promotional
        materials (upon request); and communicating with you in relation to our
        Sites, Apps or services, provision and operation of event services
        (confirming participants for events, providing and delivering prizes to
        winners, handling other complaints), and providing individually
        customized products.
      </p>
      <p>
        <strong>To operate our business:</strong> Operating and managing our
        Sites, Apps and services; providing content to you; the identification
        of uses or other wrongful uses; communicating and interacting with you
        via our Sites, Apps or services; and notifying you of changes to any of
        our Sites, Apps or services.
      </p>
      <p>
        <strong>To perform communications and marketing activities:</strong>{' '}
        Communicating with you via any means (including via email and app push
        alerts) to provide news items and other information in which you may be
        interested, subject to obtaining your prior opt-in consent to the extent
        required under applicable law; personalizing our Sites, products and
        services for you; maintaining and updating your contact information
        where appropriate; where applicable, enabling you to opt-out to withdraw
        your consent to or unsubscribe from emails sent by us, and recording
        your choice.
      </p>
      <p>
        <strong>To manage IT systems:</strong> Managing and operating our
        communications, IT and security systems; and auditing (including
        security audits) and monitoring such systems.
      </p>
      <p>
        <strong>To improve our Sites, Apps and services:</strong> Identifying
        issues related to our Sites, Apps or services; planning improvements to
        our Sites, Apps or services; and developing new Sites, Apps or services.
      </p>
      <h2>Article 3 Processed Personal Information</h2>
      <p>The personal information that we process about you is as follows:</p>
      <ul>
        <li>
          What is “required items”?: Information required to provide the
          service’s fundamental functions
        </li>
        <li>
          What is “optional items”?: Information collected additionally to
          provide specialized services (Your use of the service will not be
          restricted even if you do not enter optional items.)
        </li>
      </ul>
      <h3>[Required items]</h3>
      <table>
        <thead>
          <tr>
            <th>Service Name</th>
            <th>Process timing</th>
            <th>Personal information processed</th>
            <th>Period for retention and use</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td rowSpan={4}>Common to All Services</td>
            <td>Upon email subscription</td>
            <td>Email address</td>
            <td>
              Period stated in relevant laws and regulations or upon
              cancellation of subscription
            </td>
          </tr>
          <tr>
            <td>Upon requests for refunds</td>
            <td>
              Email address, country, payment information (last 4 digits of
              credit card number, payment date and time, etc.)
            </td>
            <td>
              Upon completion of refund process or for a period in accordance
              with relevant laws and regulations
            </td>
          </tr>
          <tr>
            <td>Upon receipt of customer inquiry</td>
            <td>Email address</td>
            <td>
              3 years after registration of inquiry in accordance with relevant
              laws and regulations
            </td>
          </tr>
          <tr>
            <td>Upon log-in and using the service</td>
            <td>
              Information automatically generated by cookie, service use history
              (date of access/log-in, IP address, fraudulent use/access, etc.),
              device information (unique device identifiers and OS version),
              logged-in country
            </td>
            <td>Period stated in relevant laws and regulations.</td>
          </tr>
          <tr>
            <td>Clear</td>
            <td>At the time of product download</td>
            <td>Email address</td>
            <td>1 year after the product download application</td>
          </tr>
        </tbody>
      </table>

      <h3>[Optional items]</h3>
      <table>
        <thead>
          <tr>
            <th>Service Name</th>
            <th>Process timing</th>
            <th>Personal information processed</th>
            <th>Period for retention and use</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Supertone Shift</td>
            <td>At the time of product download</td>
            <td>Email address</td>
            <td>
              Retention period according to relevant laws or upon withdrawal of
              subscription application
            </td>
          </tr>
        </tbody>
      </table>

      <p>
        Our service is not offered for minors under the age of 16. If we
        discover that we have collected the personal information of a minor, we
        will delete the information and cancel the user’s account. If you
        believe that we have collected information of a minor, please inform us
        via contact information in Article 13.
      </p>
      <h2>Article 4 Provision of Personal Information to Third Parties</h2>
      <p>
        Except in the following cases, we do not provide third parties with your
        personal information, unless you consent thereto or the disclosure is
        otherwise expressly prescribed in applicable law.
      </p>
      <p>
        In addition, in accordance with relevant laws and regulations, we may
        share personal information without your consent to the extent that such
        sharing is reasonably related to the purpose of the collection of such
        personal information. In the foregoing case, we will comprehensively
        consider factors such as whether the sharing is related to the original
        purpose of collection, whether the sharing is predictable in light of
        the circumstances under which the personal information was collected or
        under usual processing practices, whether the sharing unreasonably
        infringes upon your interests and whether security measures such as
        pseudonymization or encryption have been implemented.
      </p>
      <h2>Article 5 Entrustment of Personal Information</h2>
      <p>
        When necessary for the purposes of service use, performing contractual
        obligations and improving member convenience, we entrust the processing
        of personal information to specialized service providers or utilize
        specialized platforms, within the scope disclosed in this Privacy
        Notice.
      </p>
      <p>
        When signing the consignment contract to entrust the personal
        information processing, we include clauses stating the consignee’s
        responsibilities such as the prohibition of processing personal
        information for purposes other than entrusted tasks,
        technical/administrative measures, prohibition of reconsigning,
        managerial and supervision of consignee, and compensations for damages,
        and we supervise the consignees to handle the information safely.
      </p>
      <p>
        We will immediately disclose when there is any change in the entrusted
        tasks or consignees via the Notice.
      </p>
      <h2>Article 6 International Transfer of Personal Information</h2>
      <p>
        For the purposes of providing our services and enhancing users’
        convenience, we may transfer and/or manage your personal information
        overseas as follows. The details on our international transfer of
        personal information are described below.
      </p>
      <h3>1) Details About the Current Overseas Platform Utilization</h3>
      <table>
        <tbody>
          <tr>
            <td>
              Recipient of personal information (Contact information of
              information officer)
            </td>
            <td>Zendesk (privacy@zendesk.com)</td>
          </tr>
          <tr>
            <td>Country where the information is provided</td>
            <td>
              548 Market St, PMB 98174, San Francisco, CA 94104-5401, United
              States
            </td>
          </tr>
          <tr>
            <td>Date and method of provision</td>
            <td>
              Transfer via network upon registration of 1:1 customer inquiry and
              email transmissions
            </td>
          </tr>
          <tr>
            <td>Purpose of provision</td>
            <td>Handling of customer support</td>
          </tr>
          <tr>
            <td>Information provided</td>
            <td>Email address</td>
          </tr>
          <tr>
            <td>Retention and Use Period</td>
            <td>
              Upon contract termination or for 3 years after registration of
              inquiry in accordance with relevant laws and regulations
            </td>
          </tr>
        </tbody>
      </table>
      <table>
        <tbody>
          <tr>
            <td>
              Recipient of personal information (Contact information of
              information officer)
            </td>
            <td>MailerLite (support@mailerlite.com)</td>
          </tr>
          <tr>
            <td>Country where the information is provided</td>
            <td>989 Market St, San Francisco, CA 94103, United States</td>
          </tr>
          <tr>
            <td>Date and method of provision</td>
            <td>
              Transfer via network at the time of Subscribe application and
              Download application
            </td>
          </tr>
          <tr>
            <td>Purpose of provision</td>
            <td>Handling of email transmissions</td>
          </tr>
          <tr>
            <td>Information provided</td>
            <td>Email address</td>
          </tr>
          <tr>
            <td>Retention and Use Period</td>
            <td>
              Subscribe: upon contract termination or subscription cancellation
              <br />
              Download : upon contract termination or 1 year after the download
              application
            </td>
          </tr>
        </tbody>
      </table>

      <table>
        <tbody>
          <tr>
            <td>
              Recipient of personal information (Contact information of
              information officer)
            </td>
            <td>Typeform(gdpr@typeform.com)</td>
          </tr>
          <tr>
            <td>Country where the information is provided</td>
            <td>US East(Virginia)</td>
          </tr>
          <tr>
            <td>Date and method of provision</td>
            <td>Transfer via network at the time of survey response</td>
          </tr>
          <tr>
            <td>Purpose of provision</td>
            <td>
              Improvement of service quality and collection of service-related
              inquiries
            </td>
          </tr>
          <tr>
            <td>Information provided</td>
            <td>Email address, contents of the survey response</td>
          </tr>
          <tr>
            <td>Retention and Use Period</td>
            <td>
              Until the end of the contract or as per relevant legal regulations
            </td>
          </tr>
        </tbody>
      </table>

      <h2>Article 7 Retention of Personal Information</h2>
      <p>
        If we collect any personal information from you, we comply with our
        internal policy to use and retain such personal information for the
        period during which you use our services.
      </p>
      <p>
        In addition, if we need to preserve personal information in accordance
        with the provisions of applicable law or our internal policies, we will
        retain relevant personal information for a specified period as follows:
      </p>
      <h3>
        1) Details About Keeping Of Personal Information In Accordance With
        Relevant Laws
      </h3>
      <ul>
        <li>
          Records regarding contracts or withdrawal of offer, etc.: 5 years (Act
          on the Consumer Protection in Electronic Commerce)
        </li>
        <li>
          Records regarding payment and supply of goods, etc.: 5 years (Act on
          the Consumer Protection in Electronic Commerce)
        </li>
        <li>
          Records regarding the handling of consumer complaints or disputes: 3
          years (Act on the Consumer Protection in Electronic Commerce)
        </li>
        <li>
          Users’ Internet logs, materials to trace location of access, history
          of website visits: 3 months (Protection of Communications Secrets Act)
        </li>
        <li>
          Records regarding labeling and advertising: 6 months (Act on the
          Consumer Protection in Electronic Commerce)
        </li>
        <li>
          Records of processing required by tax law: 5 years (Framework Act on
          National Taxes, Income Tax Act)
        </li>
      </ul>
      <h3>
        2) Details About Information Kept In Accordance With Our Internal
        Policies
      </h3>
      <ul>
        <li>
          If prior consent has been obtained from individual members with
          respect to the period for retaining their personal information: The
          relevant retention period
        </li>
      </ul>
      <h2>
        Article 8 Procedures and Methods of Destroying Personal Information
      </h2>
      <p>
        Once the purposes of collection and use of your personal information are
        achieved, we destroy your personal information in accordance with our
        internal policies and other applicable laws. However, personal
        information collected with your consent or stored in the form of
        electronic files will be deleted using technical means that make the
        records unreproducible, and personal information printed on paper such
        as filings or printed materials will be shredded or incinerated.
      </p>
      <h2>Article 9 Your Legal Rights</h2>
      <p>
        Subject to applicable law, you may have the following rights relating to
        your personal information managed by us. You may exercise your rights at
        any time, by contacting the data protection officer via the contact
        information provided in Article 13 (Data Protection Officer).
      </p>
      <ul>
        <li>
          The right not to provide your personal information to us (however,
          please note that we will be unable to provide you with the full
          benefit of our Sites, Apps, or services, if you do not provide us with
          your personal information - e.g., we might not be able to process your
          requests without the necessary details);
        </li>
        <li>
          The right to request information regarding the nature, processing and
          disclosure of your personal information managed by us and request
          access to, or copies of such personal information;
        </li>
        <li>
          The right to request rectification of any inaccuracies in your
          personal information managed by us;
        </li>
        <li>
          The right to request, on legitimate grounds:
          <ul>
            <li>Deletion of your personal information managed by us; or</li>
            <li>
              Suspension of processing of your personal information managed by
              us;
            </li>
          </ul>
        </li>
        <li>
          The right to have certain personal information transferred to another
          entity, in a structured, commonly used and machine-readable format, to
          the extent applicable;
        </li>
        <li>
          Where we process your personal information managed by us on the basis
          of your prior consent, the right to withdraw that consent (noting that
          such withdrawal does not affect the lawfulness of any processing
          performed prior to the date on which we receive notice of such
          withdrawal, and does not prevent the processing of your personal
          information in reliance upon any other available legal bases, as
          applicable); and
        </li>
        <li>
          The right to submit complaints to an authority in charge of protection
          of data regarding the processing of your personal information managed
          by us.
        </li>
      </ul>
      <h2>
        Article 10 Technical/Administrative Measures for Protecting Personal
        Data
      </h2>
      <p>
        When handling personal information, we take appropriate measures to keep
        the information safe and prevent from loss, theft, leakage,
        falsification, or damage, and we take technical measures as follows:
      </p>
      <ul>
        <li>
          Your personal information is protected by password and encrypted
          information. However, your information can be exposed to others in
          many ways including when you use the public internet, so you need to
          be responsible for protecting your own information. We are not
          responsible for any damages that arise from your negligence or the
          fundamental nature and risks of the internet.
        </li>
        <li>
          Your personal information is protected with a password and encrypted
          information by default, and important data are protected with separate
          security functions by encrypting files and transmitted date.
        </li>
        <li>
          We use vaccine programs to prevent possible damage by computer
          viruses, and vaccine programs are updated periodically.
        </li>
        <li>
          We prevent leakage and damage of personal information caused by
          hacking and viruses by monitoring the system 24 hours a day using a
          system that detects and blocks external intrusion.
        </li>
      </ul>
      <p>
        We fully acknowledge the importance of your personal information, and
        accordingly, we have a reasonably limited number of employees handling
        personal information. The personnel in charge of protecting personal
        information conducts periodical education for the employees, putting the
        utmost effort to protect personal information. Also, we periodically
        check the compliance status of commitments stated in the policy and
        relevant employees, and when we detect any violation, we immediately
        correct and improve the issue and take necessary measures.
      </p>
      <h2>
        Article 11 Installation and Operation of Automatic Collection Tools for
        Personal Information and Refusal Thereof
      </h2>
      <p>
        We use cookies to store and discover information about members. Cookies
        are strings of small amounts of text transmitted by the website server
        to users’ computer browsers (e.g., Internet Explorer, Safari, Chrome,
        Firefox). Cookies identify each member’s computer, but do not identify
        individual members.
      </p>
      <p>
        We use Google Analytics (
        <a
          href="https://marketingplatform.google.com/about/analytics/terms/us/"
          target="_blank"
          rel="noreferrer"
        >
          Terms of Service
        </a>
        ) and API provided by Google (
        <a
          href="https://policies.google.com/privacy"
          target="_blank"
          rel="noreferrer"
        >
          Privacy Policy
        </a>
        ), for providing services and statistics analyses. For further details,
        please see our{' '}
        <a
          href="https://product.supertone.ai/cookie-policy"
          target="_blank"
          rel="noreferrer"
        >
          Cookie Policy
        </a>
        .
      </p>
      <h3>1) Operation of Cookies</h3>
      <ol>
        <li>
          Provide differentiated information depending on each individual’s
          interests
        </li>
        <li>
          Analyze members’ and non-members’ frequency of visits and time spent
          on each visit to identify members’ tastes and interests for use in
          targeted marketing
        </li>
        <li>
          Identify the traces of browsing records of contents in which members
          were interested in to provide personalized services on the next visit
        </li>
        <li>
          Analyze customers’ habits and use the results as criteria for service
          reorganization, etc.
        </li>
      </ol>
      <h3>2) Cookie options</h3>
      <p>
        By adjusting the settings on their web browser, members may accept all
        cookies, receive notifications whenever cookies are installed, or refuse
        all cookies. However, if you refuse cookies, you may not be able to use
        certain functions of the service that require login.
      </p>
      <p>
        You can set whether to permit cookies (on Internet Explorer) as follows:
      </p>
      <ol>
        <li>Go to [Tools] and [Internet Options].</li>
        <li>Click [Privacy].</li>
        <li>
          Under the tab [Settings], choose the level you want from “Accept All
          Cookies - Low - Medium - Medium High - High - Block All Cookies.”
        </li>
      </ol>
      <h3>3) Cookies expire when you close or log off from the browser.</h3>
      <h2>
        Article 12 Decision-Making Standards for Additional Use and Provision of
        Personal Information
      </h2>
      <p>
        In accordance with Article 15(3) and Article 17(4) of the Personal
        Information Act of South Korea, we may additionally use or provide your
        personal information without your consent, in consideration of Article
        14-2 of the Enforcement Decree of the Personal Information Protection
        Act of South Korea.
      </p>
      <table>
        <thead>
          <tr>
            <th>Personal information to be provided</th>
            <th>Purpose of provision</th>
            <th>Period for retention and use</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              Automatically generated information (internal identification key,
              device information, etc.), service use records (date and time of
              visit, IP address, incorrect use records, etc.), device
              information (unique device identification value, OS version),
              country accessed from
            </td>
            <td>Statistics and sales analysis on service use</td>
            <td>Period specified in the relevant laws and regulations</td>
          </tr>
        </tbody>
      </table>
      <p>
        Accordingly, we have considered the below items for additional use and
        provision of information without the user’s consent.
      </p>
      <ol>
        <li>
          Whether the purpose of additionally using and providing the personal
          information is relevant to initial purpose of collecting the
          information.
        </li>
        <li>
          Whether there is predictability about additional use and provision of
          personal information, based on the circumstances of collecting
          information or its processing practices.
        </li>
        <li>
          Whether the additional use and provision of personal information
          violates your rights.
        </li>
        <li>
          Whether security measures have been taken, such as pseudonymization or
          encryption.
        </li>
      </ol>
      <h2>Article 13 Data Protection Officer</h2>
      <p>
        In order to protect users’ personal information and handle complaints
        related to personal information, we have designated our Data Protection
        Officer as follows. Users may report all complaints related to personal
        information protection arising in the course of using our services to
        the Data Protection Officer, and we will provide prompt and sufficient
        responses to such reports.
      </p>
      <ul>
        <li>Data Protection Officer: Sung Koo Ryeo</li>
        <li>Department responsible for processing complaint: Product team</li>
        <li>
          Department responsible for information inspection request: Product
          team
        </li>
        <li>Email address: privacy@supertone.ai</li>
      </ul>
      <h2>Article 14 Handling of Comments and Complaints</h2>
      <p>
        We operate a customer services center for smooth communication and
        resolution of any comments and/or complaints presented by our users in
        connection with personal information protection. If you are a user in
        Korea, in the event of a dispute arising between us and you in
        connection with personal information protection, for which you require
        help regarding potential infringement upon your information, you can
        contact the Personal Information Infringement Report Center of the Korea
        Internet & Security Agency, the Cyber Bureau of the National Police
        Agency, or other relevant agencies.
      </p>
      <table>
        <tbody>
          <tr>
            <td>Korea Internet & Security Agency</td>
            <td>
              <a
                href="https://privacy.kisa.or.kr"
                target="_blank"
                rel="noreferrer"
              >
                https://privacy.kisa.or.kr
              </a>
            </td>
            <td>118</td>
          </tr>
          <tr>
            <td>Personal Information Dispute Mediation Committee</td>
            <td>
              <a
                href="https://www.kopico.go.kr"
                target="_blank"
                rel="noreferrer"
              >
                https://www.kopico.go.kr
              </a>
            </td>
            <td>1833-6972</td>
          </tr>
          <tr>
            <td>
              Cybercrime Investigation Division of Supreme Prosecutors’ Office
            </td>
            <td>
              <a href="https://www.spo.go.kr" target="_blank" rel="noreferrer">
                https://www.spo.go.kr
              </a>
            </td>
            <td>1303</td>
          </tr>
          <tr>
            <td>Cyber Bureau of National Police Agency</td>
            <td>
              <a href="https://cyber.go.kr" target="_blank" rel="noreferrer">
                https://cyber.go.kr
              </a>
            </td>
            <td>182</td>
          </tr>
        </tbody>
      </table>

      <h2>Article 15 Changes in Privacy Notice</h2>

      <p>
        This Privacy Policy will take into effect from March 27th, 2024 (KST).
        Please refer to the link below to see the previous version of Privacy
        Policy.
      </p>
      <ul>
        <li>Privacy Policy version number: Integrated V1.2</li>
        <li>Effective date: March 27th, 2024</li>
      </ul>
      <p>Link to previous personal information processing policy</p>
      <ul>
        <li>
          Privacy Policy V1.1 [<a href="/privacy-policy-1.1">page link</a>]
        </li>
      </ul>

      <h1>2. Jurisdiction-Specific Terms</h1>
      <p>
        The following are supplementary clauses applicable depending on your
        location or nationality. In the event of conflict between the following
        and the main text of this Privacy Notice, the following shall prevail.
      </p>
      <h2>2-1. EEA and UK</h2>
      <p>
        For the purposes of this section, the term “personal information” used
        in the General Content of this Policy shall be replaced with the term
        “personal data” – the term shall have the same meaning as set out in
        Article 1
      </p>
      <p>
        Article 1 of the General Content above shall be supplemented with the
        following text as a new final paragraph:
      </p>
      <p>
        For the purposes of the GDPR and the UK Data Protection Act 2018,
        Supertone, Inc. is the entity (or “controller”) that decides how and why
        your personal data are processed and has primary responsibility for
        complying with applicable data protection laws.
      </p>
      <p>
        Article 2 of the General Content above shall be replaced with the
        following:
      </p>
      <p>Article 2 Purposes and Legal Bases for Processing of Personal Data</p>
      <p>
        The purposes of processing of your personal data and the legal bases on
        which we rely under applicable laws are as follows:
      </p>
      <table>
        <thead>
          <tr>
            <th>Processing activity</th>
            <th>Legal basis for processing</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <strong>
                To provide our Sites, Apps, products and services:
              </strong>{' '}
              Providing our Sites, Apps, products or services; creating an
              account; providing customer support on the use of services;
              identifying and preventing wrongful uses; providing functions for
              joining the game community and for community activities;
              performing statistical analyses; providing promotional materials
              (upon request); and communicating with users in relation to our
              Sites, Apps or services; and providing and operating event
              services (confirming participants for events, providing and
              delivering prizes to winners, handling other complaints).
            </td>
            <td>
              · The processing is necessary in connection with any contract that
              you have entered into with us, or to take steps prior to entering
              into a contract with us; or
              <br />· We have a legitimate interest in carrying out the
              processing for the purpose of providing you with our Sites, Apps
              or services (to the extent that such legitimate interest is not
              overridden by your interests, fundamental rights, or freedoms); or
              <br />· We have obtained your prior consent to the processing (the
              aforementioned legal basis is only used in relation to processing
              that is entirely voluntary – it is not used for processing that is
              necessary or obligatory in any way).
            </td>
          </tr>
          <tr>
            <td>
              <strong>To operate our business:</strong> Operating and managing
              our Sites, Apps and services; providing you with content;
              authenticating users for the sales/delivery of products and the
              identification and prevention of other wrongful uses;
              communicating and interacting with you via our Sites, Apps or
              services; and notifying you of changes to any of our Sites, Apps
              or services.
            </td>
            <td>
              · The processing is necessary in connection with any contract that
              you have entered into with us, or to take steps prior to entering
              into a contract with us; or
              <br />· We have a legitimate interest in carrying out the
              processing for the purpose of providing you with our Sites, Apps
              or services (to the extent that such legitimate interest is not
              overridden by your interests, fundamental rights, or freedoms); or
              <br />· We have obtained your prior consent to the processing (the
              aforementioned legal basis is only used in relation to processing
              that is entirely voluntary – it is not used for processing that is
              necessary or obligatory in any way).
            </td>
          </tr>
          <tr>
            <td>
              <strong>
                To perform communications and marketing activities:
              </strong>{' '}
              Communicating with you via any means (including via email and app
              push alerts) to provide news items and other information in which
              you may be interested, subject to obtaining your prior opt-in
              consent to the extent required under applicable law; personalizing
              our Sites, products and services for you; maintaining and updating
              your contact information where appropriate; where applicable,
              enabling you to opt-out to withdraw your consent to or unsubscribe
              from emails sent by us, and recording your choice.
            </td>
            <td>
              · The processing is necessary in connection with any contract that
              you have entered into with us, or to take steps prior to entering
              into a contract with us; or
              <br />· We have a legitimate interest in carrying out the
              processing for the purpose of contacting you, subject always to
              compliance with applicable law (to the extent that such legitimate
              interest is not overridden by your interests, fundamental rights,
              or freedoms); or
              <br />· We have obtained your prior consent to the processing (the
              aforementioned legal basis is only used in relation to processing
              that is entirely voluntary – it is not used for processing that is
              necessary or obligatory in any way).
            </td>
          </tr>
          <tr>
            <td>
              <strong>To manage IT systems:</strong> Managing and operating our
              communications, IT and security systems; and auditing (including
              security audits) and monitoring such systems.
            </td>
            <td>
              · The processing is necessary for our compliance with a legal
              obligation; or
              <br />· We have a legitimate interest in carrying out the
              processing for the purpose of managing and maintaining our
              communications and IT systems (to the extent that such legitimate
              interest is not overridden by your interests, fundamental rights,
              or freedoms).
            </td>
          </tr>
          <tr>
            <td>
              <strong>To improve our Sites, Apps and services:</strong>{' '}
              Identifying issues related to our Sites, Apps or services;
              planning improvements to our Sites, Apps or services; and
              developing new Sites, Apps or services.
            </td>
            <td>
              · We have a legitimate interest in carrying out the processing for
              the purpose of improving our Sites, Apps or services (to the
              extent that such legitimate interest is not overridden by your
              interests, fundamental rights, or freedoms); or
              <br />· We have obtained your prior consent to the processing
              (this legal basis is only used in relation to processing that is
              entirely voluntary – it is not used for processing that is
              necessary or obligatory in any way).
            </td>
          </tr>
        </tbody>
      </table>
      <h3>Direct Marketing</h3>
      <p>
        We process personal data to contact you via email, telephone, direct
        mail or other communication formats to provide you with information
        regarding Sites, Apps or services that may be of interest to you. We
        also process personal data for the purposes of displaying content
        tailored to your use of our Sites, Apps or services. If we provide
        Sites, Apps or services to you, we may send or display information to
        you regarding our Sites, Apps or services, upcoming promotions and other
        information that may be of interest to you, including by using the
        contact details that you have provided to us, or any other appropriate
        means, subject always to obtaining your prior opt-in consent, to the
        extent required under applicable law.
      </p>
      <p>
        You may unsubscribe from our promotional email list at any time by
        simply clicking on the unsubscribe link included in every promotional
        electronic communication we send or by clicking on the unsubscribe
        button at the bottom of the subscription email. Please note that it may
        take up to 2 weeks to process your unsubscribe request, during which
        time you may continue to receive communications from us. After you
        unsubscribe, we will not send you further promotional emails, but in
        some circumstances we will continue to contact you to the extent
        necessary for the purposes of any Sites, Apps or services you have
        requested.
      </p>
      <p>
        The final paragraph of Article 4 of the General Content above shall be
        replaced with the following:
      </p>
      <p>Article 4 Disclosure of Personal Data to Third Parties</p>
      <p>
        We disclose personal data to other entities, for legitimate business
        purposes and the operation of our Sites, Apps or services to you, in
        accordance with applicable law. In addition, we disclose personal data
        to:
      </p>
      <ul>
        <li>you and, where appropriate, your appointed representatives;</li>
        <li>
          legal and regulatory authorities, upon request, or for the purposes of
          reporting any actual or suspected breach of applicable law or
          regulation;
        </li>
        <li>
          accountants, auditors, consultants, lawyers and other outside
          professional advisors to Supertone, subject to binding contractual
          obligations of confidentiality;
        </li>
        <li>
          third party processors, located anywhere in the world, subject to the
          requirements noted below in this Section Article 4;
        </li>
        <li>
          any relevant party, regulatory body, governmental authority, law
          enforcement agency or court, to the extent necessary for the
          establishment, exercise or defense of legal claims;
        </li>
        <li>
          any relevant party, regulatory body, governmental authority, law
          enforcement agency or court, for the purposes of prevention,
          investigation, detection or prosecution of criminal offences or the
          execution of criminal penalties;
        </li>
        <li>
          any relevant third-party acquirer(s) or successor(s) in title, in the
          event that we sell or transfer all or any relevant portion of our
          business or assets (including in the event of a reorganization,
          dissolution or liquidation); and
        </li>
        <li>
          any relevant third-party provider, where our Sites and our Apps use
          third party advertising, plugins or content. If you choose to interact
          with any such advertising, plugins or content, your personal data may
          be shared with the relevant third-party provider. We recommend that
          you review that third party’s privacy policy before interacting with
          its advertising, plugins or content.
        </li>
      </ul>
      <p>
        If we engage a third-party processor to process your personal data, the
        processor will be subject to binding contractual obligations to:
      </p>
      <p>
        (i) only process the personal data in accordance with our prior written
        instructions; and
      </p>
      <p>
        (ii) use measures to protect the confidentiality and security of the
        personal data; together with any additional requirements under
        applicable law.
      </p>
      <p>
        Please see Article 5 for more information on the third-party processors.
      </p>
      <ol>
        <li>Details About Providing Personal Information To Third Parties</li>
      </ol>
      <ul>
        <li>None</li>
      </ul>
      <p>
        Article 6 of the General Content above shall be replaced with the
        following:
      </p>
      <p>Article 6 International Transfer of Personal Data</p>
      <p>
        Because of the international nature of our business, we transfer
        personal data within the HYBE group, and to third parties, as discussed
        further in Article 6, in connection with the purposes set out in this
        Policy. For this reason, we transfer personal data to other countries
        that may have different laws and data protection compliance requirements
        to those that apply in the country in which you are located, including
        Korea, Japan, and the United States.
      </p>
      <p>
        If an exemption or derogation applies (e.g., where a transfer is
        necessary to establish, exercise or defend a legal claim) we may rely on
        that exemption or derogation, as appropriate. Where no exemption or
        derogation applies, and we transfer your personal data from the EEA or
        UK to recipients located outside the EEA or UK who are not in
        jurisdictions formally designated by the European Commission or UK
        Government as providing an adequate level of protection for personal
        data, we do so on the basis of template transfer clauses adopted by the
        European Commission or adopted by a Data Protection Authority and
        approved by the European Commission, or adopted by the UK Government (as
        appropriate). You are entitled to request a copy of these clauses using
        the contact details provided in Section Article 13 below.
      </p>
      <p>
        Please note that when you transfer any personal data directly to any
        HYBE entity established outside the EEA or UK, we are not responsible
        for that transfer of your personal data. We will nevertheless process
        your personal data, from the point at which we receive those data, in
        accordance with the provisions of this Policy.
      </p>
      <p>
        Article 8 of the General Content above shall be replaced with the
        following:
      </p>
      <p>Article 8 Procedures and Methods of Destroying Personal Data</p>
      <p>
        We destroy your personal information in accordance with our internal
        policies and other applicable laws.
      </p>
      <p>The following shall supplement Article 9:</p>
      <p>Article 9 Your Legal Rights</p>
      <p>
        In addition to the above rights, you remain entitled to all other
        statutory rights.
      </p>
      <p>
        You may also have the following additional rights regarding the
        processing of your personal information:
      </p>
      <ul>
        <li>
          the right to object, on grounds relating to your particular situation,
          to the processing of your personal information by us or on our behalf,
          where such processing is based on Articles 6(1)(e) (public interest)
          or 6(1)(f) (legitimate interests) of the GDPR; and
        </li>
        <li>
          the right to object to the processing of your relevant personal data
          by us or on our behalf for direct marketing purposes.
        </li>
      </ul>
      <p>
        Article 11 of the General Content above shall be replaced with the
        following:
      </p>
      <p>Article 11 Cookies and Similar Technologies</p>
      <p>
        When you visit a Site or use an App, we will typically place cookies
        onto your device or read cookies already on your device, subject always
        to obtaining your prior consent, where required, in accordance with
        applicable law. For further details, please see our{' '}
        <a
          href="https://product.supertone.ai/cookie-policy"
          target="_blank"
          rel="noreferrer"
        >
          Cookie Policy
        </a>
        .
      </p>
      <p>
        Article 13 of the General Content above shall be replaced with the
        following:
      </p>
      <p>Article 13 Contact Information</p>
      <p>
        You may contact our DPO [privacy@supertone.ai] if you wish to exercise
        your rights in connection with this Policy or your personal data.{' '}
      </p>
      <p>Article 14 of the General Content above shall not apply.</p>
      <h2>2-2. California Consumer Privacy Act (CCPA)</h2>
      <p>
        Article 7 of the General Content above shall be replaced with the
        following:
      </p>
      <p>Article 7 Data Retention Period</p>
      <p>
        We take every reasonable step to ensure that your personal information
        is only processed for the minimum period necessary for the purposes set
        out in this Policy. We will retain your personal information in a form
        that permits identification only for as long as:
      </p>
      <p>
        (1) we maintain an ongoing relationship with you; or
        <br />
        (2) your personal information is necessary in connection with the lawful
        purposes set out in this Policy, for which we have a valid legal basis.
      </p>
      <p>
        Article 9 of the General Content shall be replaced with the following:
      </p>
      <p>Article 9 California Consumer Privacy Act Disclosures</p>
      <p>
        Under the California Consumer Privacy Act (“CCPA”), we must disclose our
        practices regarding the collection, use, and disclosure of the personal
        information of California residents (“Consumers”).
      </p>
      <h3>Collection of Personal Information</h3>
      <p>
        We have collected and will collect the following general categories of
        personal information about Consumers:
      </p>
      <ul>
        <li>
          Personal identifiers, including names, postal addresses, IP addresses,
          and email addresses;
        </li>
        <li>Protected classifications, including age;</li>
        <li>Internet or other electronic network activity information; and</li>
        <li>Inferences for use in creating a consumer profile.</li>
      </ul>
      <h3>Use of Personal information</h3>
      <p>
        We may use the categories of personal information described above for
        the following business or commercial purposes:
      </p>
      <ul>
        <li>Advance our commercial or economic business interests;</li>
        <li>Maintain or service customer accounts;</li>
        <li>Provide customer service;</li>
        <li>Process or fulfill orders and transactions;</li>
        <li>Provide advertising or marketing services;</li>
        <li>Provide analytic services;</li>
        <li>Perform internal research for technological development;</li>
        <li>Ensure the quality and safety of services or devices;</li>
        <li>Improve the quality and safety of services or devices;</li>
        <li>Debugging to address impairments to operational functionality;</li>
        <li>Detect security incidents;</li>
        <li>Comply with applicable law and law enforcement requirements;</li>
        <li>
          Protect against malicious, deceptive, fraudulent or illegal activity;
        </li>
        <li>
          Prosecute those responsible for malicious, deceptive, fraudulent or
          illegal activity; and
        </li>
        <li>
          Defend against or bring legal action, claims and other liabilities.
        </li>
      </ul>
      <h3>Categories of Sources of Personal information</h3>
      <p>
        We collect or obtain personal information of Consumers from the
        following sources:
      </p>
      <ul>
        <li>Information provided to us by Consumers;</li>
        <li>Information we obtain on our own;</li>
        <li>
          Information provided to us through collaboration with Consumers;
        </li>
        <li>
          Information provided to us in the course of our relationship with
          Consumers;
        </li>
        <li>Information made public by Consumers;</li>
        <li>App data;</li>
        <li>Site data;</li>
        <li>Information registered by Consumers;</li>
        <li>Information obtained from content and advertising providers; or</li>
        <li>Information provided to us by third parties.</li>
      </ul>
      <h3>Disclosure of Personal information</h3>
      <p>
        We do not sell any personal information to third parties. In particular,
        we do not sell the personal information of minors under 16 years of age.
        In the preceding 12 months, we have disclosed the following categories
        of personal information to the following categories of recipients:
      </p>
      <table>
        <thead>
          <tr>
            <th>Categories of recipients</th>
            <th>Categories of personal information</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              Vendors who may need access to Consumers’ personal information to
              help us provide our services.
            </td>
            <td>
              <ul>
                <li>Personal identifiers</li>
                <li>
                  Categories of personal information enumerated in Cal. Civ.
                  Code § 1798.80 (e)
                </li>
                <li>Protected classification (if provided)</li>
                <li>
                  Internet or other electronic network activity information
                </li>
                <li>Inferences for use in creating a consumer profile</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              Entities who provide us with email address management and
              communication contact services, and those who analyze and enhance
              our marketing campaigns and service.
            </td>
            <td>
              <ul>
                <li>Personal identifiers</li>
                <li>
                  Categories of personal information enumerated in Cal. Civ.
                  Code § 1789.80 (e)
                </li>
                <li>Protected classification (if provided)</li>
                <li>
                  Internet or other electronic network activity information
                </li>
                <li>Inferences for use in creating a consumer profile</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>Entities that provide assistance to us.</td>
            <td>
              <ul>
                <li>Personal identifiers</li>
                <li>
                  Categories of personal information enumerated in Cal. Civ.
                  Code § 1789.80 (e)
                </li>
              </ul>
            </td>
          </tr>
        </tbody>
      </table>
      <p>
        Article 11 of the General Content above shall be replaced with the
        following:
      </p>
      <p>Article 14 California’s “Shine the Light” Law</p>
      <p>
        Under California’s “Shine the Light” law, California residents are
        entitled to ask us for a notice describing what categories of personal
        customer information we share with third parties or our affiliates in
        connection with direct marketing performed by such third parties or our
        affiliates. The aforementioned notice will identify the categories of
        information we shared with third parties and our affiliates, and will
        include a list of names and addresses of such data recipients. If you
        are a California resident and would like a copy of this Policy, please
        submit a written request to the following email address:
      </p>
      <ul>
        <li>email address: privacy@supertone.ai</li>
      </ul>
      <p>
        Article 13 of the General Content above shall be replaced with the
        following:
      </p>
      <p>Article 13 Consumer Rights under the CCPA</p>
      <p>
        If you are a Consumer, the CCPA grants you the following rights
        regarding your personal information. If you make a certain request to us
        in order to exercise, or cause an authorized representative you
        appointed to exercise on your behalf, your rights to know and delete set
        forth in the CCPA, we will generally require you to provide us with
        certain personal information for the purpose of identifying you in the
        course of handling your request and compare such personal information
        against the personal information we have collected about you to verify
        your identity (“verifiable consumer request”).
      </p>
      <p>
        Verifiable consumer requests to know or delete may be submitted through
        one of the following methods:
      </p>
      <ul>
        <li>email address: privacy@supertone.ai</li>
      </ul>
      <h3>Right to Know About Personal Information We Collected about You.</h3>
      <p>
        Consumers have the right to submit a verifiable consumer request that we
        disclose the following personal information we collected about you over
        the 12-month period preceding the verifiable consumer request, in a
        readily useable format:
      </p>
      <ul>
        <li>The categories of personal information we collected about you.</li>
        <li>
          The purposes for which the categories of personal information
          collected about you will be used.
        </li>
        <li>
          The categories of sources for the personal information we collected
          about you.
        </li>
        <li>
          The categories of third parties with whom we share your personal
          information.
        </li>
        <li>
          Our business or commercial purpose for collecting your personal
          information.
        </li>
        <li>
          The specific pieces of personal information we collected about you.
        </li>
        <li>
          The categories of personal information we have disclosed for a
          business purpose.
        </li>
      </ul>
      <h3>Right to Request Deletion of Personal Information.</h3>
      <p>
        Consumers have the right to request that we delete any personal
        information we have collected from them.
      </p>
      <h3>Right to Non-Discrimination.</h3>
      <p>
        Consumers have the right to be free from discrimination when they
        exercise their rights under the CCPA and, should you exercise those
        rights, we cannot:
      </p>
      <ol>
        <li>Deny you goods or services;</li>
        <li>Charge you a different price or rate for goods or services;</li>
        <li>
          Provide you with a different level of quality of goods or services;
        </li>
        <li>
          Suggest that you may receive a different level of quality of goods or
          services.
        </li>
      </ol>
      <h3>Notice of Financial Incentive</h3>
      <p>
        We offer consumers who provide personal identifiers, including their
        name, home address, and email address, and commercial information,
        including their purchase history and access to events. As participants
        in these programs, consumers will have the opportunity to win prizes.
        Consumers may opt-in to our community events by signing up on our
        website. Consumers may cancel their participation in these events at any
        time.
      </p>
      <h3>Authorized Agent</h3>
      <p>
        Under the CCPA, you may appoint an authorized agent to submit requests
        to exercise your rights on your behalf. Should you choose to do so, for
        your and our protection, we will require your authorized agent to
        provide us with a signed permission demonstrating they are authorized to
        submit a request on your behalf. We note, should your authorized agent
        fail to submit proof that they have been authorized to act on your
        behalf, we will deny their request.
      </p>
      <p>Article 14 of the General Content above shall not apply.</p>
      <h2>2-3. Japan</h2>
      <p>
        According to the purpose of the Article, the “relevant laws” and
        “relevant Korean laws” stated in this Policy shall be replaced with the
        Act on the Protection of Personal Information (Act No. 57 of 2003) and
        relevant Japanese laws.
      </p>
      <p>
        The terms used in Article 2 of the General Content above shall be
        replaced with the following:
      </p>
      <p>
        The description of this Policy shall be replaced with the following: “We
        process your personal information for the following purposes pursuant to
        applicable law.”
      </p>
      <p>
        Article 4 (Provision of Personal Information to Third Parties), Article
        5 (Entrustment of Personal Information), and Article 6 (International
        Transfer of Personal Information) of the General Content above shall be
        replaced with the following:
      </p>
      <p>
        Except in the following cases, we do not provide third parties with your
        personal information, unless you consent thereto or the disclosure is
        otherwise expressly prescribed in applicable law.
      </p>
      <p>Details About Providing Personal Information To Third Parties</p>
      <ul>
        <li>None</li>
      </ul>
      <p>Details About International Transfer of Personal Information</p>
      <h3>1. Details About the Current Overseas Platform Utilization</h3>
      <table>
        <tbody>
          <tr>
            <td>
              Recipient of personal information (Contact information of
              information officer)
            </td>
            <td>Zendesk (privacy@zendesk.com)</td>
          </tr>
          <tr>
            <td>Country where the information is provided</td>
            <td>
              548 Market St, PMB 98174, San Francisco, CA 94104-5401, United
              States
            </td>
          </tr>
          <tr>
            <td>Date and method of provision</td>
            <td>
              Transfer via network upon registration of 1:1 customer inquiry and
              email transmissions
            </td>
          </tr>
          <tr>
            <td>Purpose of provision</td>
            <td>Handling of customer support</td>
          </tr>
          <tr>
            <td>Information provided</td>
            <td>Email address</td>
          </tr>
          <tr>
            <td>Retention and Use Period</td>
            <td>
              Upon contract termination or for 3 years after registration of
              inquiry in accordance with relevant laws and regulations
            </td>
          </tr>
        </tbody>
      </table>
      <table>
        <tbody>
          <tr>
            <td>
              Recipient of personal information (Contact information of
              information officer)
            </td>
            <td>MailerLite (support@mailerlite.com)</td>
          </tr>
          <tr>
            <td>Country where the information is provided</td>
            <td>989 Market St, San Francisco, CA 94103, United States</td>
          </tr>
          <tr>
            <td>Date and method of provision</td>
            <td>
              Transfer via network at the time of Subscribe application and
              Download application
            </td>
          </tr>
          <tr>
            <td>Purpose of provision</td>
            <td>Handling of email transmissions</td>
          </tr>
          <tr>
            <td>Information provided</td>
            <td>Email address</td>
          </tr>
          <tr>
            <td>Retention and Use Period</td>
            <td>
              Subscribe: upon contract termination or subscription cancellation
              <br />
              Download : upon contract termination or 1 year after the download
              application
            </td>
          </tr>
        </tbody>
      </table>

      <table>
        <tbody>
          <tr>
            <td>
              Recipient of personal information (Contact information of
              information officer)
            </td>
            <td>Typeform(gdpr@typeform.com)</td>
          </tr>
          <tr>
            <td>Country where the information is provided</td>
            <td>US East(Virginia)</td>
          </tr>
          <tr>
            <td>Date and method of provision</td>
            <td>Transfer via network at the time of survey response</td>
          </tr>
          <tr>
            <td>Purpose of provision</td>
            <td>
              Improvement of service quality and collection of service-related
              inquiries
            </td>
          </tr>
          <tr>
            <td>Information provided</td>
            <td>Email address, contents of the survey response</td>
          </tr>
          <tr>
            <td>Retention and Use Period</td>
            <td>
              Until the end of the contract or as per relevant legal regulations
            </td>
          </tr>
        </tbody>
      </table>

      <h2>【Joint Use】</h2>
      <p>
        The companies listed below and included in the above details about third
        parties provided with personal information are part of the company’s
        group. The types of jointly used personal information; the bounds of
        jointly using parties; the purpose of using; and the names of the
        companies and their representatives with responsibilities of managing
        personal information are listed below.
      </p>
      <p>
        Companies included in the group (jointly using parties): HYBE Corp.,
        HYBE IM, BIGHIT MUSIC Co., Ltd., PLEDIS Entertainment Co., Ltd., BELIFT
        LAB Co., Ltd., KOZ Entertainment Co., Ltd., Source Music Co., Ltd., ADOR
        Co., Ltd., Weverse Company Inc., Weverse Japan Inc., HYBE Japan Inc.,
        HYBE Labels JAPAN, NAECO, HYBE America, Inc., Weverse America Inc.
      </p>
      <ul>
        <li>
          Types of information for joint use: Same as listed in “Details About
          Providing Personal Information To Third Parties” and “Types of
          personal information”.
        </li>
        <li>
          Purpose of use: Same as listed in “Details About Providing Personal
          Information To Third Parties” and “Types of personal information”.
        </li>
        <li>
          Management responsibilities: The names of the company’s
          representatives are the same as listed in each company’s website.
        </li>
      </ul>
      <h2>References</h2>
      <h3>
        Personal Information Protection Systems of Countries Receiving
        Information
      </h3>
      <p>
        Supertone, Inc. (hereinafter “the company”) provides various services to
        its customers, and in the process, the company comes across many
        occasions in which the user’s personal information needs to be provided
        to companies located outside the country or region of the user’s
        residence, with the user’s consent. The company’s Privacy Policy
        describes that the company may provide the user’s personal information
        to companies outside the country.
      </p>
      <p>
        This page describes the details of the company’s provision of user’s
        personal information to companies outside the country or region of the
        user’s residence, using the privacy regulations of other countries as
        reference.
      </p>
      <p>
        The privacy regulations of various countries have been examined
        according to the below standards.
      </p>
      <h4>■ Existence of the data privacy regulations</h4>
      <p>
        This is an indication of whether the country has established data
        protection and privacy regulations, including a comprehensive
        regulation.
      </p>
      <h4>■ Reference for data privacy regulations</h4>
      <p>
        The following types of information are used as references for the
        standards of each country’s privacy regulations.
      </p>
      <h5>
        ▪ Whether the standards of the privacy regulations of the corresponding
        country/region are equivalent to those of Japan
      </h5>
      <p>
        It will be indicated if the privacy regulations of the corresponding
        country/region is equivalent to those of Japan.
      </p>
      <h5>▪ EU adequacy decision</h5>
      <p>
        It will be indicated if the EU adequacy decision has been adopted in the
        corresponding country/region. EU adequacy decision refers to the
        European Commission’s decision that indicates the corresponding
        country/region has secured an adequate level of data protection. If a
        country has adopted the EU adequacy decision, we can expect the same
        level of protection of personal information in the country/region as in
        Japan.
      </p>
      <h5>▪ Participation in APEC CBPR</h5>
      <p>
        It will be indicated if the corresponding country/region is a
        participating country of the APEC CBPR system.
      </p>
      <p>
        When the corresponding country/region is a participating country of the
        APEC CBPR system, we can expect the same level of protection of personal
        information in the country/region as in Japan, as it signifies that the
        laws of the corresponding country/region would be enforced in compliance
        with the APEC privacy framework and an enforcement office.
      </p>
      <h5>▪ Satisfaction of OECD’s Eight Principles</h5>
      <p>
        It will be indicated, as necessary, if the privacy regulation of the
        corresponding country/region includes rules that meet OECD’s Eight
        Principles.
      </p>
      <p>
        OECD’s Eight Principles provides the basic principles to be referenced
        in performing international efforts for personal information protection,
        and they are considered to be the substantial global standards for each
        country’s establishment of the personal information protection system.
      </p>
      <p>
        In this page, it will be indicated if the data privacy regulation of the
        corresponding country/region includes rules corresponding to OECD’s
        Eight Principles, according to the following legends for each of OECD’s
        Eight Principles.
      </p>
      <p>
        Legends: ○ Rules are included in the comprehensive system; ∆ Certain
        rules are included in the comprehensive system/Rules are included in
        individual systems; — No rules could be found
      </p>
      <p>OECD’s Eight Principles are as indicated in (1) to (8) below.</p>
      <p>1. Collection Limitation Principle</p>
      <p>
        Personal data should be obtained by lawful and fair means and, where
        appropriate, with the knowledge or consent of the data subject.
      </p>
      <p>2. Data Quality Principle</p>
      <p>
        Personal data should be relevant to the purposes for which they are to
        be used, and should be accurate, complete and kept up-to-date
      </p>
      <p>3. Purpose Specification Principle</p>
      <p>
        The purposes for which personal data are collected should be specified,
        and the subsequent use limited to the fulfillment of those purposes.
      </p>
      <p>4. Use Limitation Principle</p>
      <p>
        Personal data should not be used for purposes other than those specified
        except with the consent of the data subject or by the authority of law.
      </p>
      <p>5. Security Safeguards Principle</p>
      <p>
        Personal data should be protected by reasonable security safeguards
        against such risks as loss, destruction, use, modification or disclosure
        of data.
      </p>
      <p>6. Openness Principle</p>
      <p>
        There should be a general policy of openness about developments,
        practices and policies with respect to personal data. Means should be
        readily available of establishing the existence of personal data, and
        the purposes of their use, as well as the data controller.
      </p>
      <p>7. Individual Participation Principle</p>
      <p>
        An individual should have the right to obtain from a data controller, or
        otherwise, confirmation of whether or not the data controller has data
        relating to him, and to challenge data relating to him.
      </p>
      <p>8. Accountability Principle</p>
      <p>
        A data controller should be accountable for complying with measures
        which give effect to the principles stated above.
      </p>
      <h5>
        ■ Existence of system that may significantly affect the rights and
        interests of individuals
      </h5>
      <p>
        Information on whether there is a system that may significantly affect
        the rights and interests of individuals pursuant to the transfer of
        personal data to the corresponding country/region in comparison to
        Japan’s data privacy regulation is indicated.
      </p>
      <p>
        Specifically, the following information which may significantly affect
        the rights and interests of individuals in the system of the
        corresponding country/region is indicated; namely, (1) existence of
        rules which directly obligate the recipient of personal information,
        which was collected within the corresponding country/region, to retain
        such personal information within the corresponding country/region, or
        obligates the recipient of personal information to substantially retain
        such personal information within the corresponding country/region by
        imposing restrictions on carrying such personal information outside the
        corresponding country/region (system related to data localization), and
        (2) existence of rules which allow the government to access the personal
        data retained by private businesses or obligate private businesses to
        provide personal data to the government under laws for the purpose of
        enforcement of criminal laws and/or national security safeguards (system
        related to government access).
      </p>
      <p>
        Please note that the information may not be the latest at present, as
        each country periodically updates the information related to the data
        privacy regulation. Please review the information on the data privacy
        regulations of foreign countries provided by the Personal Information
        Protection Commission of Japan. (Source: Personal Information Protection
        Commission of Japan,{' '}
        <a
          href="https://www.ppc.go.jp/personalinfo/legal/kaiseihogohou/"
          target="_blank"
          rel="noreferrer"
        >
          https://www.ppc.go.jp/personalinfo/legal/kaiseihogohou/
        </a>
        )
      </p>
      <h5>
        South Korea (
        <a
          href="https://www.privacy.go.kr/front/contents/cntntsView.do?contsNo=36"
          target="_blank"
          rel="noreferrer"
        >
          Link
        </a>
        )
      </h5>
      <ul>
        <li>
          Existence of the data privacy regulations
          <ul>
            <li>Comprehensive system: Yes</li>
          </ul>
        </li>

        <li>
          Reference for data privacy regulations
          <ul>
            <li>EU adequacy decision: Adopted</li>{' '}
            <li>CBPR system: Participating</li>
          </ul>
        </li>
        <li>
          Existence of system that will significantly affect the rights and
          interests of individuals
          <ul>
            <li>
              Disclosure and notification of personal information of convicted
              sex offenders
            </li>
          </ul>
        </li>
      </ul>
      <h5>
        Germany (
        <a
          href="https://www.pipc.go.kr/np/cop/bbs/selectBoardList.do?bbsId=BS271&mCode=D060030010"
          target="_blank"
          rel="noreferrer"
        >
          Link
        </a>
        )
      </h5>
      <ul>
        <li>
          Existence of the data privacy regulations
          <ul>
            <li>Comprehensive system: Yes</li>
          </ul>
        </li>
        <li>
          Reference for data privacy regulations
          <ul>
            <li>
              There is a data privacy regulation acknowledged as being the same
              level as in Japan.
            </li>
          </ul>
        </li>
      </ul>
      <h5>
        United States of America (
        <a
          href="https://www.pipc.go.kr/np/cop/bbs/selectBoardList.do?bbsId=BS271&mCode=D060030010"
          target="_blank"
          rel="noreferrer"
        >
          Link
        </a>
        )
      </h5>
      <ul>
        <li>
          Existence of the data privacy regulations
          <ul>
            <li>Comprehensive system: No</li>
            <li>System of individual sectors/regions: Yes</li>
          </ul>
        </li>

        <li>
          Reference for data privacy regulations
          <ul>
            <li>EU adequacy decision: Not adopted</li>
            <li>CBPR system: Participating</li>
          </ul>
        </li>
        <li>
          Existence of system that will significantly affect the rights and
          interests of individuals
          <ul>
            <li>None</li>
          </ul>
        </li>
      </ul>
      <h5>
        Singapore (
        <a
          href="https://www.pipc.go.kr/np/cop/bbs/selectBoardList.do?bbsId=BS271&mCode=D060030010"
          target="_blank"
          rel="noreferrer"
        >
          Link
        </a>
        )
      </h5>
      <ul>
        <li>
          Existence of the data privacy regulations
          <ul>
            <li>Comprehensive system: Yes</li>
          </ul>
        </li>
        <li>
          Reference for data privacy regulations
          <ul>
            <li>EU adequacy decision: Not adopted</li>
            <li>CBPR system: Participating</li>
          </ul>
        </li>
        <li>
          Existence of system that will significantly affect the rights and
          interests of individuals
          <ul>
            <li>
              There is a system related to government access that may
              significantly affect the rights and interests of individuals.
            </li>
          </ul>
        </li>
      </ul>
      <h5>
        United Kingdom (
        <a
          href="https://www.pipc.go.kr/np/cop/bbs/selectBoardList.do?bbsId=BS271&mCode=D060030010"
          target="_blank"
          rel="noreferrer"
        >
          Link
        </a>
        )
      </h5>
      <ul>
        <li>
          Existence of the data privacy regulations
          <ul>
            <li>Comprehensive system: Yes</li>
          </ul>
        </li>
        <li>
          Reference for data privacy regulations
          <ul>
            <li>
              There is a data privacy regulation acknowledged as being the same
              level as in Japan.
            </li>
          </ul>
        </li>
      </ul>

      <h5>
        Japan (
        <a
          href="https://www.ppc.go.jp/personalinfo/"
          target="_blank"
          rel="noreferrer"
        >
          Link
        </a>
        )
      </h5>
      <ul>
        <li>
          Existence of the data privacy regulations
          <ul>
            <li>Comprehensive system: Yes</li>
          </ul>
        </li>
        <li>
          Reference for data privacy regulations
          <ul>
            <li>EU adequacy decision: Adopted</li>
            <li>CBPR system: Participating</li>
          </ul>
        </li>
        <li>
          Existence of system that will significantly affect the rights and
          interests of individuals
          <ul>
            <li>None</li>
          </ul>
        </li>
      </ul>

      <h2>【Provision by Entrustment】</h2>
      <p>
        The company has entrusted specialized companies and platforms with the
        tasks of processing personal information within reasonable scopes, in
        order to achieve the purposes of providing service and fulfilling
        contracts, and improving the convenience of customers.
      </p>

      <p>Details About Providing Personal Information To Third Parties</p>
      <ul>
        <li>None</li>
      </ul>

      <p>Details About International Transfer of Personal Information</p>

      <h3>1. Details About the Current Overseas Platform Utilization</h3>

      <table>
        <tbody>
          <tr>
            <td>
              Recipient of personal information (Contact information of
              information officer)
            </td>
            <td>Zendesk (privacy@zendesk.com)</td>
          </tr>
          <tr>
            <td>Country where the information is provided</td>
            <td>
              548 Market St, PMB 98174, San Francisco, CA 94104-5401, United
              States
            </td>
          </tr>
          <tr>
            <td>Date and method of provision</td>
            <td>
              Transfer via network upon registration of 1:1 customer inquiry and
              email transmissions
            </td>
          </tr>
          <tr>
            <td>Purpose of provision</td>
            <td>Handling of customer support</td>
          </tr>
          <tr>
            <td>Information provided</td>
            <td>Email address</td>
          </tr>
          <tr>
            <td>Retention and Use Period</td>
            <td>
              Upon contract termination or for 3 years after registration of
              inquiry in accordance with relevant laws and regulations
            </td>
          </tr>
        </tbody>
      </table>

      <table>
        <tbody>
          <tr>
            <td>
              Recipient of personal information (Contact information of
              information officer)
            </td>
            <td>MailerLite (support@mailerlite.com)</td>
          </tr>
          <tr>
            <td>Country where the information is provided</td>
            <td>989 Market St, San Francisco, CA 94103, United States</td>
          </tr>
          <tr>
            <td>Date and method of provision</td>
            <td>
              Transfer via network at the time of Subscribe application and
              Download application
            </td>
          </tr>
          <tr>
            <td>Purpose of provision</td>
            <td>Handling of email transmissions</td>
          </tr>
          <tr>
            <td>Information provided</td>
            <td>Email address</td>
          </tr>
          <tr>
            <td>Retention and Use Period</td>
            <td>
              Subscribe: upon contract termination or subscription cancellation
              <br />
              Download : upon contract termination or 1 year after the download
              application
            </td>
          </tr>
        </tbody>
      </table>

      <table>
        <tbody>
          <tr>
            <td>
              Recipient of personal information (Contact information of
              information officer)
            </td>
            <td>Typeform(gdpr@typeform.com)</td>
          </tr>
          <tr>
            <td>Country where the information is provided</td>
            <td>US East(Virginia)</td>
          </tr>
          <tr>
            <td>Date and method of provision</td>
            <td>Transfer via network at the time of survey response</td>
          </tr>
          <tr>
            <td>Purpose of provision</td>
            <td>
              Improvement of service quality and collection of service-related
              inquiries
            </td>
          </tr>
          <tr>
            <td>Information provided</td>
            <td>Email address, contents of the survey response</td>
          </tr>
          <tr>
            <td>Retention and Use Period</td>
            <td>
              Until the end of the contract or as per relevant legal regulations
            </td>
          </tr>
        </tbody>
      </table>

      <p>
        When signing a contract with entrusted companies, important issues are
        addressed including prohibition of processing personal information for
        purposes other than entrusted purposes; technical and managerial
        measures to protect information; prohibition of re-entrustment;
        management and supervision of entrusted companies; and indemnification
        for possible damages. The company supervises the entrusted companies to
        ensure they handle the information safely.
      </p>

      <p>
        If there are any changes in the entrusted tasks or list of entrusted
        companies, we will make sure to notify by sharing the revised Privacy
        Policy.
      </p>

      <h3>References</h3>
      <p>
        Personal Information Protection Systems of Countries Receiving
        Information
      </p>
      <p>
        Supertone, Inc. (hereinafter “the company”) provides various services to
        its customers, and in the process, the company comes across many
        occasions in which the user’s personal information needs to be provided
        to companies located outside the country or region of the user’s
        residence, with the user’s consent. The company’s Privacy Policy
        describes that the company may provide the user’s personal information
        to companies outside the country.
      </p>

      <p>
        This page describes the details of the company’s provision of user’s
        personal information to companies outside the country or region of the
        user’s residence, using the privacy regulations of other countries as
        reference.
      </p>

      <p>
        The privacy regulations of various countries have been examined
        according to the below standards.
      </p>

      <h4>■ Existence of the data privacy regulations</h4>
      <p>
        This is an indication of whether the country has established data
        protection and privacy regulations, including a comprehensive
        regulation.
      </p>

      <h4>■ Reference for data privacy regulations</h4>
      <p>
        The following types of information are used as references for the
        standards of each country’s privacy regulations.
      </p>
      <h5>
        ▪ Whether the standards of the privacy regulations of the corresponding
        country/region are equivalent to those of Japan
      </h5>
      <p>
        It will be indicated if the privacy regulations of the corresponding
        country/region is equivalent to those of Japan.
      </p>
      <h5>▪ EU adequacy decision</h5>
      <p>
        It will be indicated if the EU adequacy decision has been adopted in the
        corresponding country/region. EU adequacy decision refers to the
        European Commission’s decision that indicates the corresponding
        country/region has secured an adequate level of data protection. If a
        country has adopted the EU adequacy decision, we can expect the same
        level of protection of personal information in the country/region as in
        Japan.
      </p>

      <h5>▪ Participation in APEC CBPR</h5>
      <p>
        It will be indicated if the corresponding country/region is a
        participating country of the APEC CBPR system.
      </p>
      <p>
        When the corresponding country/region is a participating country of the
        APEC CBPR system, we can expect the same level of protection of personal
        information in the country/region as in Japan, as it signifies that the
        laws of the corresponding country/region would be enforced in compliance
        with the APEC privacy framework and an enforcement office.
      </p>

      <h5>▪ Satisfaction of OECD’s Eight Principles</h5>
      <p>
        It will be indicated, as necessary, if the privacy regulation of the
        corresponding country/region includes rules that meet OECD’s Eight
        Principles.
      </p>

      <p>
        OECD’s Eight Principles provides the basic principles to be referenced
        in performing international efforts for personal information protection,
        and they are considered to be the substantial global standards for each
        country’s establishment of the personal information protection system.
      </p>

      <p>
        In this page, it will be indicated if the data privacy regulation of the
        corresponding country/region includes rules corresponding to OECD’s
        Eight Principles, according to the following legends for each of OECD’s
        Eight Principles.
      </p>

      <p>
        Legends: ○ Rules are included in the comprehensive system; ∆ Certain
        rules are included in the comprehensive system/Rules are included in
        individual systems; — No rules could be found
      </p>

      <p>OECD’s Eight Principles are as indicated in (1) to (8) below.</p>
      <p>1. Collection Limitation Principle</p>
      <p>
        Personal data should be obtained by lawful and fair means and, where
        appropriate, with the knowledge or consent of the data subject.
      </p>
      <p>2. Data Quality Principle</p>
      <p>
        Personal data should be relevant to the purposes for which they are to
        be used, and should be accurate, complete and kept up-to-date
      </p>
      <p>3. Purpose Specification Principle</p>
      <p>
        The purposes for which personal data are collected should be specified,
        and the subsequent use limited to the fulfillment of those purposes.
      </p>
      <p>4. Use Limitation Principle</p>
      <p>
        Personal data should not be used for purposes other than those specified
        except with the consent of the data subject or by the authority of law.
      </p>
      <p>5. Security Safeguards Principle</p>
      <p>
        Personal data should be protected by reasonable security safeguards
        against such risks as loss, destruction, use, modification or disclosure
        of data.
      </p>
      <p>6. Openness Principle</p>
      <p>
        There should be a general policy of openness about developments,
        practices and policies with respect to personal data. Means should be
        readily available of establishing the existence of personal data, and
        the purposes of their use, as well as the data controller.
      </p>
      <p>7. Individual Participation Principle</p>
      <p>
        An individual should have the right to obtain from a data controller, or
        otherwise, confirmation of whether or not the data controller has data
        relating to him, and to challenge data relating to him.
      </p>
      <p>8. Accountability Principle</p>
      <p>
        A data controller should be accountable for complying with measures
        which give effect to the principles stated above.
      </p>

      <h5>
        ■ Existence of system that may significantly affect the rights and
        interests of individuals
      </h5>
      <p>
        Information on whether there is a system that may significantly affect
        the rights and interests of individuals pursuant to the transfer of
        personal data to the corresponding country/region in comparison to
        Japan’s data privacy regulation is indicated.
      </p>

      <p>
        Specifically, the following information which may significantly affect
        the rights and interests of individuals in the system of the
        corresponding country/region is indicated; namely, (1) existence of
        rules which directly obligate the recipient of personal information,
        which was collected within the corresponding country/region, to retain
        such personal information within the corresponding country/region, or
        obligates the recipient of personal information to substantially retain
        such personal information within the corresponding country/region by
        imposing restrictions on carrying such personal information outside the
        corresponding country/region (system related to data localization), and
        (2) existence of rules which allow the government to access the personal
        data retained by private businesses or obligate private businesses to
        provide personal data to the government under laws for the purpose of
        enforcement of criminal laws and/or national security safeguards (system
        related to government access).
      </p>

      <p>
        Please note that the information may not be the latest at present, as
        each country periodically updates the information related to the data
        privacy regulation. Please review the information on the data privacy
        regulations of foreign countries provided by the Personal Information
        Protection Commission of Japan. (Source: Personal Information Protection
        Commission of Japan,{' '}
        <a
          href="https://www.ppc.go.jp/personalinfo/legal/kaiseihogohou/"
          target="_blank"
          rel="noreferrer"
        >
          https://www.ppc.go.jp/personalinfo/legal/kaiseihogohou/
        </a>
        )
      </p>

      <h5>
        South Korea (
        <a
          href="https://www.privacy.go.kr/front/contents/cntntsView.do?contsNo=36"
          target="_blank"
          rel="noreferrer"
        >
          Link
        </a>
        )
      </h5>
      <ul>
        <li>
          Existence of the data privacy regulations
          <ul>
            <li>Comprehensive system: Yes</li>
          </ul>
        </li>
        <li>
          Reference for data privacy regulations
          <ul>
            <li>EU adequacy decision: Adopted</li>
            <li>CBPR system: Participating</li>
          </ul>
        </li>
        <li>
          Existence of system that will significantly affect the rights and
          interests of individuals
          <ul>
            <li>
              Disclosure and notification of personal information of convicted
              sex offenders
            </li>
          </ul>
        </li>
      </ul>

      <h5>
        Germany (
        <a
          href="https://www.privacy.go.kr/pic/nation_germany.do"
          target="_blank"
          rel="noreferrer"
        >
          Link
        </a>
        )
      </h5>
      <ul>
        <li>
          Existence of the data privacy regulations
          <ul>
            <li>Comprehensive system: Yes</li>
          </ul>
        </li>
        <li>
          Reference for data privacy regulations
          <ul>
            <li>
              There is a data privacy regulation acknowledged as being the same
              level as in Japan.
            </li>
          </ul>
        </li>
      </ul>

      <h5>
        United States of America (
        <a
          href="https://www.privacy.go.kr/pic/nation_usa.do"
          target="_blank"
          rel="noreferrer"
        >
          Link
        </a>
        )
      </h5>
      <ul>
        <li>
          Existence of the data privacy regulations
          <ul>
            <li>Comprehensive system: No</li>
            <li>System of individual sectors/regions: Yes</li>
          </ul>
        </li>
        <li>
          Reference for data privacy regulations
          <ul>
            <li>EU adequacy decision: Not adopted</li>
            <li>CBPR system: Participating</li>
          </ul>
        </li>
        <li>
          Existence of system that will significantly affect the rights and
          interests of individuals
          <ul>
            <li>None</li>
          </ul>
        </li>
      </ul>

      <h5>
        Singapore (
        <a
          href="https://www.privacy.go.kr/pic/nation_singapore.do"
          target="_blank"
          rel="noreferrer"
        >
          Link
        </a>
        )
      </h5>
      <ul>
        <li>
          Existence of the data privacy regulations
          <ul>
            <li>Comprehensive system: Yes</li>
          </ul>
        </li>
        <li>
          Reference for data privacy regulations
          <ul>
            <li>EU adequacy decision: Not adopted</li>
            <li>CBPR system: Participating</li>
          </ul>
        </li>
        <li>
          Existence of system that will significantly affect the rights and
          interests of individuals
          <ul>
            <li>
              There is a system related to government access that may
              significantly affect the rights and interests of individuals.
            </li>
          </ul>
        </li>
      </ul>

      <h5>
        United Kingdom (
        <a
          href="https://www.privacy.go.kr/pic/nation_england.do"
          target="_blank"
          rel="noreferrer"
        >
          Link
        </a>
        )
      </h5>
      <ul>
        <li>
          Existence of the data privacy regulations
          <ul>
            <li>Comprehensive system: Yes</li>
          </ul>
        </li>
        <li>
          Reference for data privacy regulations
          <ul>
            <li>
              There is a data privacy regulation acknowledged as being the same
              level as in Japan.
            </li>
          </ul>
        </li>
      </ul>
      <h5>
        Japan (
        <a
          href="https://www.ppc.go.jp/personalinfo/"
          target="_blank"
          rel="noreferrer"
        >
          Link
        </a>
        )
      </h5>
      <ul>
        <li>
          Existence of the data privacy regulations
          <ul>
            <li>Comprehensive system: Yes</li>
          </ul>
        </li>
        <li>
          Reference for data privacy regulations
          <ul>
            <li>EU adequacy decision: Adopted</li>
            <li>CBPR system: Participating</li>
          </ul>
        </li>
        <li>
          Existence of system that will significantly affect the rights and
          interests of individuals
          <ul>
            <li>None</li>
          </ul>
        </li>
      </ul>

      <p>Article 12 of the General Content above is inapplicable.</p>
      <p>
        The following shall be added to Article 13 of the General Content above.
      </p>
      <ul>
        <li>COMPANY: Supertone</li>
        <li>
          ADDRESS: 13, Hakdong-ro 9-gil, Gangnam-gu, Seoul, South Korea
          (Nonhyeon-dong)
        </li>
        <li>CEO: Kyogu Lee</li>
      </ul>
      <p>Article 14 of the General Content above is inapplicable.</p>
    </div>
  );
};

export default PrivacyPolicyPage;
