import { useSearchParams } from 'react-router-dom';
import cn from 'classnames';

import useScrollToTop from '../../common/hook/useScrollToTop';
import ClearLogo from './ClearLogo';

import styles from './clear.module.scss';
import { FormEvent, useState } from 'react';
import { apiPost } from '../../util/api';

const ACTIVATE_CLEAR_API = process.env.REACT_APP_ACTIVATE_CLEAR_API as string;

const ActivateClearPage = () => {
  const [searchParams] = useSearchParams();
  const [licenseKey, setLicenseKey] = useState('');
  const [licenseFile, setLicenseFile] = useState<Blob | null>(null);
  const [errorMessage, setErrorMessage] = useState('');

  useScrollToTop();

  const deviceName = searchParams.get('deviceName');
  const deviceId = searchParams.get('deviceId');

  const onLicenseKeyChange = (e: FormEvent<HTMLInputElement>) => {
    setLicenseKey(e.currentTarget.value);
  };

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setLicenseFile(null);
    setErrorMessage('');

    if (!licenseKey) {
      return;
    }

    try {
      const r = await apiPost(ACTIVATE_CLEAR_API, {
        licenseKey,
        deviceId,
        deviceName,
      });

      if (r.status === 200) {
        const { licenseKey: sdkLicenseKey, signature } = r.data.data;

        const str = [licenseKey, signature, sdkLicenseKey].join('/');
        const blob = new Blob([str], { type: 'text/plain' });
        setLicenseFile(blob);
      }
    } catch (e: any) {
      switch (e.response?.status) {
        case 400:
          setErrorMessage('Invalid license. Please enter a valid license key.');
          break;

        case 500:
          setErrorMessage(
            'Activation limit exceeded. Please deactivate from another device.'
          );
          break;

        default:
          setErrorMessage('An unknown error has occurred.');
          break;
      }
    }
  };

  const noDevice = !deviceName || !deviceId;
  const isActivateDisabled = noDevice || !licenseKey;

  return (
    <div className={cn('activate-page', styles.ActivatePage)}>
      <div className={styles.ProductTitle} style={{ marginTop: '58px' }}>
        <ClearLogo />
      </div>

      <h1>Activate Supertone Clear</h1>

      {noDevice ? (
        <div style={{ marginBottom: '60px' }}>
          <p className={styles.ErrorTitle}>No device found.</p>
          <p className={styles.ErrorContent}>
            Please access this page from your Supertone Clear plug-in.
          </p>
        </div>
      ) : (
        <>
          <section>
            <h2>Activation Instructions</h2>

            <ol>
              <li>Enter a valid license key for Supertone Clear.</li>
              <li>Click “Activate”</li>
              <li>Once successfully activated, download license file.</li>
              <li>
                Go back to Supertone Clear and finish by loading the license
                file in the plug-in.
              </li>
            </ol>

            <p className={styles.Note}>
              *Please check the device name below to see if you are activating
              the correct device.
            </p>
          </section>

          <hr />

          <section>
            <h2>Device Name</h2>
            <p>{deviceName}</p>
          </section>

          <section>
            <h2>License Key</h2>
            <form onSubmit={onSubmit}>
              <input
                type="text"
                name="licenseKey"
                placeholder="License Key"
                value={licenseKey}
                onChange={onLicenseKeyChange}
              />
              <input
                type="submit"
                value="Activate"
                disabled={isActivateDisabled}
              />
              <input type="hidden" name="deviceId" value={deviceId} />
            </form>
          </section>

          {licenseFile && (
            <>
              <hr />

              <section>
                <h2 className={styles.Success}>Activation Succeeded!</h2>
                <a
                  type="button"
                  href={URL.createObjectURL(licenseFile)}
                  download={`${deviceName.replace(/[\W_]/g, '_')}.clearlicense`}
                >
                  Download License File
                </a>
              </section>
            </>
          )}

          {errorMessage && (
            <>
              <section>
                <h2 className={styles.Error}>Activation Failed.</h2>
                <p className={styles.Error}>{errorMessage}</p>
              </section>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default ActivateClearPage;
