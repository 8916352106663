// Get cookie by name.
export const getCookie = (name: string): string | null => {
  const cookies = document.cookie.split(';');
  const cookie = cookies.find((cookie) => cookie.trim().startsWith(`${name}=`));

  return cookie ? cookie.split('=')[1] : null;
};

// Set cookie.
export const setCookie = (name: string, value: string, days: number) => {
  const date = new Date();
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);

  const expires = `expires=${date.toUTCString()}`;

  document.cookie = `${name}=${value};${expires};path=/;domain=${process.env.REACT_APP_HOST}`;
};
