import useScrollToTop from '../common/hook/useScrollToTop';

import styles from './legal.module.scss';

const CookiePolicyPage = () => {
  useScrollToTop();

  return (
    <div className={styles.LegalContainer}>
      <h1>Cookie Policy</h1>

      <p>
        This Notice is issued by Supertone Inc. (“Supertone”, “we”, “us” and
        “our”).
      </p>

      <h2 style={{ marginTop: '113px' }}>
        Websites to which this Notice applies
      </h2>

      <p>
        This Notice applies to the following websites that are operated or
        controlled by Supertone:
      </p>
      <ul>
        <li>
          <a href="https://supertone.ai" target="_blank" rel="noreferrer">
            https://supertone.ai
          </a>
        </li>
        <li>
          <a
            href="https://product.supertone.ai"
            target="_blank"
            rel="noreferrer"
          >
            https://product.supertone.ai
          </a>
        </li>
      </ul>

      <h2 style={{ marginTop: '113px' }}>What are cookies?</h2>

      <p>
        Cookies are small text files that may be stored on your device when you
        visit a website. They are generally used to make websites work, to keep
        track of your movements within the website, to remember your login
        details, to remember your preferences and interests, and so on.
      </p>
      <p>
        There are different types of cookies, and they can be distinguished on
        the basis of their origin, function and lifespan. Important
        characteristics of cookies include the following:
      </p>
      <ul>
        <li>
          First party cookies are cookies that are placed by the website you are
          visiting, while third party cookies are placed by a website other than
          the one you are visiting. Please note that we do not control the
          collection or further use of data by third parties.
        </li>
        <li>
          Essential cookies are necessary cookies used for the technical
          operation of a website (e.g., they enable you to move around on a
          website and use its features). They are necessary for the safety of
          the service as stipulated in the GDPR Article 6(1)(b), which is
          related to the "performance of a contract", and Article 6(1)(f), which
          is related to "legitimate interests".
        </li>
        <li>
          Analytical/Performance Cookies collect data on the performance of a
          website such as the number of visitors, the time spent on the website
          and error messages.
        </li>
        <li>
          Functionality and Profile Cookies increase the usability of a website
          by remembering your choices (e.g. language, region, login, and so on).
        </li>
        <li>
          Advertising / Targeting Cookies enable a website to send you
          personalized advertising.
        </li>
        <li>
          Session cookies are temporary cookies that are erased once you close
          your browser while persistent or permanent cookies stay on your device
          until you manually delete them or until your browser deletes them
          based on the duration period specified in the persistent cookie file.
        </li>
      </ul>

      <p>
        More information on all aspects of cookies can be found on{' '}
        <a
          href="http://www.allaboutcookies.org"
          target="_blank"
          rel="noreferrer"
        >
          www.allaboutcookies.org
        </a>
        . Please note that Supertone has no affiliation with, and is not
        responsible for, this third party website.
      </p>

      <h2 style={{ marginTop: '113px' }}>Why do we use cookies?</h2>

      <p>We may use cookies to:</p>
      <ul>
        <li>distinguish between visitors;</li>
        <li>improve the use and the functionality of our Sites;</li>
        <li>process your purchase orders;</li>
        <li>
          tailor our Sites and products to your needs and preferences; and
        </li>
        <li>
          analyse how our Sites are used and compile anonymous and aggregate
          statistics.
        </li>
      </ul>
      <p>We do not use the collected information to create visitor profiles.</p>

      <h2 style={{ marginTop: '113px' }}>What types of cookies do we use?</h2>

      <p>
        An overview of the cookies used on our Sites is set out in the following
        table.
      </p>

      <div style={{ overflowX: 'auto' }}>
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Third Party?</th>
              <th>Duration</th>
              <th>Purpose</th>
              <th>Types and purposes of data collected</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>_ga, _ga_XXXXXXXXXX</td>
              <td>Third Party</td>
              <td>1 year</td>
              <td>Analytical Performance</td>
              <td>
                For analyzing the user's website visit and browsing history and
                the website's performance if the user has agreed.
              </td>
            </tr>
            <tr>
              <td>sup_consent</td>
              <td>-</td>
              <td>1 year</td>
              <td>Essential</td>
              <td>
                For storing unique session key for Help Center Functionality.
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <p>
        Please note that the processing of your personal data in connection with
        cookies, and for other purposes, is governed by our{' '}
        <a href="/privacy-policy">Privacy Policy</a>. Our services utilize
        external platform providers to deliver the services. You can find
        information about the cookies used by these external platform providers
        in their respective cookie policies.
      </p>

      <div style={{ overflowX: 'auto' }}>
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Privacy Policy</th>
              <th>Purpose</th>
              <th>Types and purposes of data collected</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>MailerLite</td>
              <td>
                <a
                  href="https://www.mailerlite.com/legal/privacy-policy"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://www.mailerlite.com/legal/privacy-policy
                </a>
              </td>
              <td>
                <a
                  href="https://www.mailerlite.com/legal/cookie-policy"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://www.mailerlite.com/legal/cookie-policy
                </a>
              </td>
              <td>
                Please visit the “Cookie Settings” menu in Mailerlite’s website
              </td>
            </tr>
            <tr>
              <td>Zendesk</td>
              <td>
                <a
                  href="https://www.zendesk.com/company/agreements-and-terms/privacy-notice/"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://www.zendesk.com/company/agreements-and-terms/privacy-notice/
                </a>
              </td>
              <td>
                <a
                  href="https://www.zendesk.kr/company/agreements-and-terms/cookie-notice/"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://www.zendesk.kr/company/agreements-and-terms/cookie-notice/
                </a>
              </td>
              <td>
                Please visit the “Cookie Settings” menu in Zendesk’s website
              </td>
            </tr>
            <tr>
              <td>Paddle</td>
              <td>
                <a
                  href="https://www.paddle.com/legal/privacy"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://www.paddle.com/legal/privacy
                </a>
              </td>
              <td>
                <a
                  href="https://www.iubenda.com/privacy-policy/83216669/cookie-policy"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://www.iubenda.com/privacy-policy/83216669/cookie-policy
                </a>
              </td>
              <td>
                Please visit the “Cookie Preferences” menu in Paddle’s website
              </td>
            </tr>
            <tr>
              <td>Typeform</td>
              <td>
                <a
                  href="https://admin.typeform.com/to/dwk6gt/"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://admin.typeform.com/to/dwk6gt/
                </a>
              </td>
              <td>
                <a
                  href="https://admin.typeform.com/to/dwk6gt/"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://admin.typeform.com/to/dwk6gt/
                </a>
              </td>
              <td>Typeform Website &gt; Cookie Preference Menu</td>
            </tr>
          </tbody>
        </table>
      </div>

      <p>
        In addition, we may also use web beacons (or clear GIFs) and other
        similar technologies in addition to, or in combination with, cookies. A
        web beacon is typically a transparent graphic image (usually 1 pixel x 1
        pixel) that is placed on a website or in an email and it helps us to
        understand the behaviour of visitors to our Sites. More information on
        web beacons can be found at{' '}
        <a
          href="http://www.allaboutcookies.org/faqs/beacons.html"
          target="_blank"
          rel="noreferrer"
        >
          http://www.allaboutcookies.org/faqs/beacons.html
        </a>
        . Please note that Supertone has no affiliation with, and is not
        responsible for, this third party website.
        <br />
        <br />
        Our Sites use Google Analytics, a web analytics service provided by
        Google, Inc. ("Google"). The information generated by Google Analytics
        Cookies about your use of the website (including your IP address) will
        be transmitted to and stored by Google on servers in the United States.
        <br />
        <br />
        Google will use this information for the purpose of evaluating your use
        of our Sites, compiling reports on website activity for website
        operators and providing other services relating to website activity and
        internet usage. Google may also transfer this information to third
        parties where required to do so by law, or where such third parties
        process the information on Google's behalf. Google will not associate
        your IP address with any other data held by Google. You may refuse the
        use of cookies by selecting the appropriate settings on your browser,
        however please note that if you do this you may not be able to use the
        full functionality of our Sites. By using our Sites, you consent to the
        processing of data about you by Google in the manner and for the
        purposes set out above.
        <br />
        <br />
        For more information please visit{' '}
        <a
          href="http://www.google.com/analytics/"
          target="_blank"
          rel="noreferrer"
        >
          http://www.google.com/analytics/
        </a>
      </p>

      <h2 style={{ marginTop: '113px' }}>
        How can you control cookies and web beacons?
      </h2>

      <p>
        Most internet browsers are set to automatically accept cookies.
        Depending on your browser, you can set your browser to warn you before
        accepting cookies, or you can set it to refuse them. Please refer to the
        'help' button (or similar) on your browser to learn more about how you
        can do this.
        <br />
        <br />
        Disabling cookies may impact your experience on our Sites.
        <br />
        <br />
        If you use different devices to access our Sites, you will need to
        ensure that each browser of each device is set to your cookie
        preference.
        <br />
        <br />
        More information on how to manage cookies is available from:{' '}
        <a
          href="http://www.allaboutcookies.org/manage-cookies/"
          target="_blank"
          rel="noreferrer"
        >
          http://www.allaboutcookies.org/manage-cookies/
        </a>
        . Please note that Supertone has no affiliation with, and is not
        responsible for, this third party website.
        <br />
        <br />
        In addition, you may opt-out from certain cookies by visiting the
        following third party websites and selecting which company cookies you
        would like to opt-out from:
        <br />
        <br />
        <a
          href="http://www.aboutads.info/choices/#completed"
          target="_blank"
          rel="noreferrer"
        >
          http://www.aboutads.info/choices/#completed
        </a>{' '}
        and{' '}
        <a
          href="http://www.youronlinechoices.com/"
          target="_blank"
          rel="noreferrer"
        >
          http://www.youronlinechoices.com/
        </a>
        . Please note that Supertone has no affiliation with, and is not
        responsible for, these third party websites.
      </p>

      <p style={{ marginTop: '101px' }}>Last updated : March 27, 2024</p>
    </div>
  );
};

export default CookiePolicyPage;
