import useScrollToTop from '../common/hook/useScrollToTop';

import styles from './legal.module.scss';

const PersonalInformationDownloadPage = () => {
  useScrollToTop();

  return (
    <div className={styles.LegalContainer}>
      <h1>Consent for the Collection and Use of Personal Information</h1>

      <p>Category: Software Product Download</p>

      <p>Entity of Collection and Use: Supertone, Inc.</p>

      <p>
        Purpose of Collection and Use: To deliver download links for software
        product
      </p>

      <p>Items of Collection and Use: Email address</p>

      <p>Retention and Use Period: 1 year after collection</p>

      <p>
        - You have the right to refuse this consent, and if you refuse, the use
        of the service for the stated purpose may be limited.
      </p>
    </div>
  );
};

export default PersonalInformationDownloadPage;
