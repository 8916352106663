import cn from 'classnames';
import useScrollToTop from '../../common/hook/useScrollToTop';
import ClearLogo from './ClearLogo';
import Newsletter from '../../common/v4/Newsletter';

import styles from './clear.module.scss';

const DownloadClearPage = () => {
  useScrollToTop();

  return (
    <div className={cn('download-page', styles.DownloadPage)}>
      <div className={styles.ProductTitle} style={{ marginTop: '58px' }}>
        <ClearLogo />
      </div>

      <h1>Download</h1>

      <p>
        Enter your email address to get instant download links for our free
        plugin trials. Our trial versions output noise every 60 seconds — until
        activated with a license key.
      </p>

      <section className={styles.DownloadNewsletter}>
        <Newsletter
          componentId="7670879"
          formId="99074860243747951"
          buttonLabel="Get Download Links"
          hideMarketingConsent={true}
          successContent={
            <p style={{ textAlign: 'center' }}>
              Your download links will be delivered to you shortly.
            </p>
          }
          personalInformationLink="/personal-information-download"
        />
      </section>
    </div>
  );
};

export default DownloadClearPage;
