import { useEffect, useRef } from 'react';

const useSleep = (callback: () => void, delay: number) => {
  const savedCallback = useRef<() => void>();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    const handler = () => savedCallback.current?.();

    if (delay !== null) {
      const id = setTimeout(handler, delay);
      return () => clearTimeout(id);
    }
  }, [delay]);
};

export default useSleep;
